import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { ScrollRestoration } from 'react-router-dom';
import {
	Box,
	Flex,
	Container,
	Heading,
	Stack,
	Text,
	VStack,
	useColorModeValue,
} from '@chakra-ui/react';

import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import FooterTwoColumnBackground from '../components/FooterTwoColumnBackground';
import FirstLastName, {
	IFirstLastNameValues,
} from '../components/form/FirstLastName/FirstLastName';
import EmailInput, { IEmailInputValues } from '../components/form/Email/Email';
import CommentsTextarea, { ICommentsValues } from '../components/form/Textarea/Comments';
import SecondaryButton from '../components/buttons/secondaryButton';

interface IContactUs extends IFirstLastNameValues, IEmailInputValues, ICommentsValues {}

const ContactUsValidationSchema = yup.object({
	firstName: yup.string().required('First Name is required'),
	lastName: yup.string().required('Last Name is required'),
	email: yup.string().email('Must be a valid email').required('Email is required'),
	comments: yup.string().required('Comments are required'),
});
const initialValues = {
	firstName: undefined,
	lastName: undefined,
};

const ContactUsPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const [showHideThanks, setShowHideThanks] = useState(false);
	const borderColor = useColorModeValue('gray.200', '#012d43');

	/* Because I don't want this in the HTML */
	function submitCallback(values: IContactUs) {
		setTimeout(() => {
			console.debug('[DRR] ~ file: ContactUsPage.tsx:21 ~ submitCallback ~ values:', values);
			setShowHideThanks(true);
		}, 500);
	}

	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<Box paddingTop={'70px'} paddingBottom={'140px'}>
				<Container maxWidth={'965px'}>
					<Flex maxWidth={'720px'} flexDirection={'column'} marginX="auto">
						<Heading as="h1" fontSize={'24px'}>
							CONTACT US
						</Heading>
						<Text fontSize={'16px'} marginTop={'30px'} marginBottom={'20px'} maxWidth={'720px'}>
							Use the form below to contact our 24/7 Customer Service team. We're typically able to
							respond within 24 hours! For immediate assistance, please call our toll-free number or
							see Frequently Asked Questions.
						</Text>
						<Text fontSize={'16px'}>
							<strong>Phone Number:</strong> 877-527-0000
						</Text>
					</Flex>

					<Formik
						initialValues={initialValues}
						validationSchema={ContactUsValidationSchema}
						onSubmit={(values) => {
							submitCallback(values);
						}}
					>
						<Form>
							<Box
								// backgroundColor="white"
								backgroundColor={useColorModeValue('gray.700', 'white')}
								boxShadow={'base'}
								width="100%"
								height="550px"
								marginTop={'40px'}
								paddingX={'40px'}
								paddingY={'50px'}
								borderRadius={10}
								// color="#002D43"
								color={useColorModeValue('white', 'black')}
							>
								{!showHideThanks && (
									<>
										<Stack direction={['column', 'row']}>
											<Flex
												id="columnOne"
												width={'385px'}
												justifyContent={'space-between'}
												flexDirection={'column'}
												marginRight={'18px'}
											>
												<FirstLastName
													firstName={{ name: 'firstName' }}
													firstNameLabel="First Name"
													lastName={{ name: 'lastName' }}
													lastNameLabel="Last Name"
													required
													size="lg"
													borderColor={borderColor}
												/>
												<EmailInput
													email={{ name: 'email' }}
													size="lg"
													emailLabel="Email"
													required
													borderColor={borderColor}
												/>
											</Flex>
											<Flex
												id="columnTwo"
												width={'385px'}
												justifyContent={'flex-start'}
												flexDirection={'column'}
												marginLeft={'18px'}
											>
												<CommentsTextarea
													commentsLabel="Questions/Comments:"
													comments={{ name: 'comments' }}
													textAreaProps={{
														isRequired: true,
														borderColor: borderColor,
													}}
												/>
											</Flex>
										</Stack>
										<VStack marginTop={'60px'}>
											<SecondaryButton buttonCopy="Submit" type="submit" />
										</VStack>
									</>
								)}
								{showHideThanks && (
									<Flex
										direction={'column'}
										alignItems={'center'}
										justifyContent={'center'}
										width="100%"
										height="100%"
									>
										<Heading as="h2">Thank you for contacting us!</Heading>
									</Flex>
								)}
							</Box>
						</Form>
					</Formik>
					<Text fontSize={'16px'} textAlign="left" marginTop={'30px'} paddingLeft={'40px'}>
						<strong>Mailing Address</strong>
						<br />
						Pinnacle Outdoor
						<br />
						P.O. Box 123456
						<br />
						Wethersfield, CT 06129-0728
					</Text>
				</Container>
			</Box>
			<FooterTwoColumnBackground />
		</Box>
	);
});

export const AnimatedContactUsPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <ContactUsPage ref={ref} />),
);

export default ContactUsPage;
