import { useFormikContext } from 'formik';
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

interface IEmailInput {
	email: Partial<HTMLInputElement>;
	emailLabel: string;
	required: boolean;
	size: string;
	borderColor?: string;
}

export interface IEmailInputValues {
	email?: string;
}

export const initialEmailInputValues = {
	email: undefined,
};

const EmailInput: React.FC<IEmailInput> = ({ required, email, emailLabel, size, borderColor }) => {
	const { values, handleChange, handleBlur, errors, touched } =
		useFormikContext<IEmailInputValues>();

	return (
		<>
			<FormControl isRequired={required} isInvalid={Boolean(touched.email && errors.email)}>
				<FormLabel htmlFor="email" id="emailLabel">
					{emailLabel}
				</FormLabel>
				<Input
					id="email"
					name={email.name ? email.name : 'email'}
					type="email"
					required={required}
					value={values.email}
					onChange={handleChange}
					onBlur={handleBlur}
					size={size ? size : 'md'}
					borderColor={borderColor}
				/>
				{errors.email && touched.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
			</FormControl>
		</>
	);
};

export default EmailInput;
