import {
	AspectRatio,
	Button,
	Container,
	Heading,
	Image,
	SimpleGrid,
	Stack,
	Text,
	useColorMode,
	VStack,
} from '@chakra-ui/react';

interface IHeroImageRight {
	marginTop?: string;
	heroImageUrl: string;
	heroImageAlt: string;
	contentBgColor?: string;
}

const HeroImageRight: React.FC<IHeroImageRight> = ({
	marginTop,
	heroImageAlt,
	heroImageUrl,
	contentBgColor,
}) => {
	const { colorMode } = useColorMode();
	return (
		<Container maxW={'1280px'} mt={marginTop}>
			<SimpleGrid
				columns={{ base: 1, md: 2 }}
				gap={0}
				// bg={colorMode === 'light' ? 'secondary.500' : undefined}
				// bg={'secondary.500'}
			>
				<VStack
					// spacing={{ base: '8', md: '12' }}
					justifyContent="center"
					// bg={colorMode === 'light' ? 'secondary.500' : ''}
					bg={contentBgColor}
					px={'40px'}
				>
					<Stack spacing={{ base: '4', md: '6' }}>
						{/* TODO Image */}
						<Heading
							size={{ base: 'md', md: 'xl' }}
							textTransform={'uppercase'}
							color={colorMode === 'light' ? 'white' : undefined}
						>
							trek through winter 2023
						</Heading>
						<Text
							fontSize={{ base: 'lg', md: 'xl' }}
							color={colorMode === 'light' ? 'white' : undefined}
						>
							Earn up to 350 points just for completing 5 simple activities - accept the challenge
							today
						</Text>
					</Stack>
					<Stack direction={{ base: 'column', md: 'row' }} spacing="3">
						<Button
							textTransform="uppercase"
							variant="solid"
							bg="#d49283"
							color="secondary.500"
							_hover={{ bg: '#d49283', opacity: '0.8' }}
							size="lg"
							mt={'30px'}
						>
							ENROLL TODAY
						</Button>
					</Stack>
				</VStack>
				<AspectRatio ratio={4 / 3}>
					<Image objectFit="cover" src={heroImageUrl} alt={heroImageAlt} />
				</AspectRatio>
			</SimpleGrid>
		</Container>
	);
};
export default HeroImageRight;
