import { Flex, Heading, HStack, Img, Text, VStack, Button } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const MapTrail: React.FC = () => {
	return (
		<Flex as="section" bg="#e7d5d1" color="secondary.500" py={'10'} justifyContent={'center'}>
			<VStack gap={2} maxW={'1280px'}>
				<Heading
					as={'h2'}
					size={{ base: 'md', md: 'lg' }}
					fontWeight="bold"
					textTransform="uppercase"
				>
					earn points now. treat yourself later.
				</Heading>
				<Text fontSize={{ base: 'lg' }} maxW="3xl">
					Getting started is easy
				</Text>
				<HStack gap={0}>
					<Img
						src="https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Step1.png"
						alt="Step 1"
					/>
					<Img src="/media/images/DottedLine_1to2.png" alt="Step 1 to 2" w={'370px'} h={'80px'} />
					<Img
						src="https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Step2.png"
						alt="Step 2"
					/>
					<Img src="/media/images/DottedLine_2to3.png" alt="Step 2 to 3" w={'360px'} h={'80px'} />
					<Img
						src="https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Step3.png"
						alt="Step 3"
					/>
				</HStack>
				<HStack alignItems={'flex-start'}>
					<VStack w={'calc(33.3333% - 100px);'} mx={'50px'}>
						<Text fontSize={'large'} textTransform={'uppercase'} fontWeight={'bold'}>
							Sign up
						</Text>
						<Text fontSize={'medium'}>Register for free and start earning points.</Text>
					</VStack>
					<VStack w={'calc(33.3333% - 100px);'} mx={'50px'}>
						<Text fontSize={'large'} textTransform={'uppercase'} fontWeight={'bold'}>
							earn points
						</Text>
						<Text fontSize={'medium'}>
							Earn points for gearing up, then get even more for completing activities.
						</Text>
					</VStack>
					<VStack w={'calc(33.3333% - 100px);'} mx={'50px'}>
						<Text fontSize={'large'} textTransform={'uppercase'} fontWeight={'bold'}>
							get rewarded
						</Text>
						<Text fontSize={'medium'}>
							Redeem your points for rewards like gift cards, accessories, swag and chances to
							participate in promotions.
						</Text>
					</VStack>
				</HStack>
				<Button
					as={NavLink}
					to={'/join'}
					textTransform="uppercase"
					variant="solid"
					bg="#d49283"
					color="secondary.500"
					_hover={{ bg: '#d49283', opacity: '0.8' }}
					size="lg"
					mt={'30px'}
				>
					Join Now
				</Button>
			</VStack>
		</Flex>
	);
};
export default MapTrail;
