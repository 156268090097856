export function getCookie(name: string): string | undefined {
	const cookies = document.cookie.split(';');
	for (const cookie of cookies) {
		const trimmedCookie = cookie.trim();
		if (trimmedCookie.startsWith(name + '=')) {
			return trimmedCookie.substring(name.length + 1);
		}
	}
	return undefined;
}

export function setCookie(name: string, value: string, seconds: number): void {
	const date = new Date();
	date.setTime(date.getTime() + 1000 * seconds);
	const expires = 'expires=' + date.toUTCString();
	document.cookie = name + '=' + value + ';' + expires + ';path=/';
}

export function expireCookie(name: string): void {
	const date = new Date();
	date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000); //Yesterday
	const expires = 'expires=' + date.toUTCString();
	document.cookie = name + '=;' + expires + ';path=/';
}
