import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

interface IPrimaryLink {
	to: string;
	linkCopy: string;
}

const PrimaryLink: React.FC<IPrimaryLink> = ({ to, linkCopy }) => {
	return (
		<Button
			as={NavLink}
			to={to}
			size="lg"
			textTransform="uppercase"
			textColor="white"
			marginX={'20px'}
			colorScheme="primary"
		>
			{linkCopy}
		</Button>
	);
};

export default PrimaryLink;
