import {
	Button,
	Flex,
	HStack,
	Link,
	Stack,
	Text,
	useColorModeValue as mode,
} from '@chakra-ui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import { formatPrice } from '../../../domain/utils/PriceUtils';
import OrderSummaryItem from '../orders/OrderSummaryItem';

interface ICartPageOrderSummaryProps {
	subtotal?: number;
	shippingTax?: number;
	numItems?: number;
}

const CartPageOrderSummary: React.FC<ICartPageOrderSummaryProps> = ({
	subtotal,
	shippingTax,
	numItems,
}) => {
	const navigate = useNavigate();
	return (
		<Stack spacing="6" width="full" maxW="sm">
			<Stack spacing="6" bg={mode('gray.50', 'gray.700')} rounded="lg" padding="8">
				<Text fontSize="lg" fontWeight="bold">
					Order Summary
				</Text>

				<Stack spacing="4">
					<OrderSummaryItem
						label="Subtotal"
						value={formatPrice(subtotal ?? 0, { locale: 'en-US', currency: 'POINTS' })}
					/>
					<OrderSummaryItem
						label="Shipping + Tax"
						value={formatPrice(shippingTax ?? 0, { locale: 'en-US', currency: 'USD' })}
					/>
					{/* <OrderSummaryItem label="Coupon Code">
					<Link href="#" textDecor="underline">
						Add coupon code
					</Link>
				</OrderSummaryItem> */}
					<Flex justify="space-between" fontWeight="semibold">
						<Text>Total</Text>
						<Text>
							{formatPrice((subtotal ?? 0) + (shippingTax ?? 0), {
								locale: 'en-US',
								currency: 'POINTS',
							})}
						</Text>
					</Flex>
				</Stack>
			</Stack>
			<Button
				colorScheme="blue"
				size="lg"
				fontSize="md"
				isDisabled={numItems === 0 || !numItems}
				disabled={numItems === 0 || !numItems}
				onClick={() => {
					navigate('/checkout');
				}}
			>
				Checkout
			</Button>
			<HStack mt="6" fontWeight="semibold">
				<p>or</p>
				<Link as={NavLink} to={'/rewards'} color={mode('blue.500', 'blue.200')}>
					Continue shopping
				</Link>
			</HStack>
		</Stack>
	);
};
export default CartPageOrderSummary;
