import React from 'react';
import { Button } from '@chakra-ui/react';

interface ISecondaryButton {
	buttonCopy: string;
	type?: 'button' | 'submit' | 'reset' | undefined;
}

const SecondaryButton: React.FC<ISecondaryButton> = ({ buttonCopy, type }) => {
	return (
		<Button
			colorScheme="secondary"
			backgroundColor={'secondary.500'}
			size="lg"
			textTransform={'uppercase'}
			textColor={'white'}
			mx={'20px'}
			type={type ? type : 'submit'}
		>
			{buttonCopy}
		</Button>
	);
};

export default SecondaryButton;
