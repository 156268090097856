import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Heading,
	HStack,
	Icon,
	Link,
	Skeleton,
	Stack,
	Text,
	useColorModeValue,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { FiHeart } from 'react-icons/fi';
import ProductPriceTag from './ProductPriceTag';
import ProductImageGallery from './ProductImageGallery';
import { useApiManager, useCartManager } from '../../../domain/hooks';
import { IGetRewardsRequestParameters, IGetRewardsResponse } from '../../../domain/models';
import { objectToUrlParams } from '../../../domain/utils/GeneralUtils';
import { useQuery } from '@tanstack/react-query';
import { getCookie } from '../../../domain/utils/CookieUtils';
import { isNumber } from 'lodash';
import { useContext } from 'react';
import { AppContext } from '../../../data/globalstate';
import { NavLink, useNavigate } from 'react-router-dom';
import { TbJewishStarFilled } from 'react-icons/tb';
import { GiSevenPointedStar } from 'react-icons/gi';

interface IProductDetailBlock {
	productId?: string;
}

const ProductDetailBlock: React.FC<IProductDetailBlock> = ({ productId }) => {
	const { user } = useContext(AppContext);
	const navigate = useNavigate();
	const { getApiUrl } = useApiManager();
	const { addCartItem } = useCartManager();
	const toast = useToast();

	const rewardParams: IGetRewardsRequestParameters = { RewardId: productId };
	const rewardsUrlParams = objectToUrlParams(rewardParams);
	const textAccentColor = useColorModeValue('gray.600', 'gray.400');

	const { data, isLoading, error } = useQuery<IGetRewardsResponse>({
		queryKey: ['reward', 'get', rewardsUrlParams],
		// queryFn: () => getRewardsFn(),
		queryFn: () => {
			// console.log('ProductBlock::getRewards');
			// console.log(getRewardsParams);

			const token = getCookie('accessToken');
			let url = getApiUrl() + '/Reward';

			// if (getRewardsParams) {
			// const urlParams = decodeURI(new URLSearchParams(getRewardsParams).toString());
			// const urlParams = objectToUrlParams(getRewardsParams);
			// console.log(urlParams);
			url = url + '?' + rewardsUrlParams;
			// }

			// console.log(url);

			const options: RequestInit = {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + (token ? token : ''),
					'Access-Control-Allow-Origin': '*',
				},
				// body: JSON.stringify({
				// 	AccountNumber: data.accountNumber,
				// 	PromotionName: data.promotionName,
				// }),
			};

			return new Promise<IGetRewardsResponse>((resolve, reject) => {
				fetch(url, options)
					.then(async (response) => {
						const data = (await response.json()) as IGetRewardsResponse;
						resolve(data);
					})
					.catch((e: unknown) => {
						reject(e);
					});
			});
		},
	});

	return (
		<Box
			maxW="7xl"
			mx="auto"
			px={{ base: '4', md: '8', lg: '12' }}
			py={{ base: '6', md: '8', lg: '12' }}
		>
			{error && (
				<Alert status="error">
					<AlertIcon />
					<Box>
						<AlertTitle>{error.name}</AlertTitle>
						<AlertDescription>{error.message}</AlertDescription>
					</Box>
				</Alert>
			)}
			<HStack mb={2}>
				<Text>&lt;</Text>
				<Link as={NavLink} to={'/rewards'}>
					Back to rewards catalog
				</Link>
			</HStack>
			<Stack
				direction={{ base: 'column-reverse', lg: 'row' }}
				spacing={{ base: '6', lg: '12', xl: '16' }}
			>
				<Skeleton flex={1} isLoaded={!isLoading}>
					{data?.rewards?.[0].voucherTypeSubCategory === 'SWEEPSTAKE' && (
						<VStack position={'relative'}>
							<HStack
								backgroundColor={'primary.500'}
								color={'white'}
								py={2}
								justifyContent={'center'}
								fontWeight={'semibold'}
								alignSelf={'stretch'}
							>
								<Text textTransform={'uppercase'}>{`sweepstakes entry`}</Text>
							</HStack>
							<HStack mb={2}>
								<Icon
									as={TbJewishStarFilled}
									boxSize={8}
									fill={'primary.500'}
									transform={'rotate(30deg)'}
								/>
								<Icon
									as={TbJewishStarFilled}
									boxSize={8}
									fill={'#e7d5d1'}
									transform={'rotate(30deg)'}
								/>
								<Icon
									as={TbJewishStarFilled}
									boxSize={8}
									fill={'primary.500'}
									transform={'rotate(30deg)'}
								/>
							</HStack>
							<Box
								position={'absolute'}
								bottom={'-80%'}
								left={'0%'}
								zIndex={40}
								textAlign={'center'}
								maxW={'80px'}
								transform={'rotate(-30deg)'}
							>
								<Icon
									as={GiSevenPointedStar}
									boxSize={36}
									fill={'secondary.500'}
									transform={'rotate(30deg)'}
									pos={'absolute'}
									top={'-2.5rem'}
									left={'-2rem'}
									zIndex={0}
								/>
								<Icon
									as={TbJewishStarFilled}
									boxSize={5}
									fill={'#e7d5d1'}
									transform={'rotate(30deg)'}
									position={'relative'}
									zIndex={1}
								/>
								{/* TODO We'll need to get this dynamic content from somewhere. */}
								<Text
									position={'relative'}
									zIndex={1}
									fontSize={'small'}
									textTransform={'uppercase'}
									fontWeight={'semibold'}
									lineHeight={1}
								>
									Grand Canyon
								</Text>
							</Box>
						</VStack>
					)}
					{data?.rewards?.[0].voucherTypeSubCategory !== 'SWEEPSTAKE' && (
						<HStack
							backgroundColor={'primary.500'}
							color={'white'}
							py={2}
							justifyContent={'center'}
							fontWeight={'semibold'}
						>
							{data?.rewards?.[0].quantity && (
								<Text
									textTransform={'uppercase'}
								>{`${data.rewards[0].quantity.toString()} in stock`}</Text>
							)}
							{!data?.rewards?.[0].quantity && (
								<Text textTransform={'uppercase'}>{`sold out`}</Text>
							)}
						</HStack>
					)}
					<ProductImageGallery
						rootProps={{ overflow: 'hidden', flex: '1' }}
						images={data?.rewards?.[0].imageAlternatives ?? []}
					/>
				</Skeleton>
				<Skeleton isLoaded={!isLoading}>
					<Stack
						spacing={{ base: '6', lg: '8' }}
						maxW={{ lg: 'sm' }}
						justify="left"
						textAlign={'left'}
					>
						<Stack spacing={{ base: '3', md: '4' }}>
							<Stack spacing="3">
								{/* NOTE Product Rating Not Supported */}
								{/* <HStack alignSelf="baseline">
									<ProductRating defaultValue={4} size="sm" />
									<Link
										href="#"
										fontSize="sm"
										fontWeight="medium"
										color={textAccentColor}
									>
										12 Reviews
									</Link>
								</HStack> */}
								<Heading size="lg" fontWeight="medium">
									{/* Classic Black */}
									{data?.rewards?.[0]?.voucherName}
								</Heading>
							</Stack>

							{data?.rewards?.[0]?.voucherTypeDescription && (
								<Text color={textAccentColor}>{data.rewards[0]?.voucherTypeDescription}</Text>
							)}
						</Stack>
						{/* <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '6', md: '8' }}>
							<Stack flex="1">
								<ProductColorPicker
									defaultValue="Black"
									options={[
										{ label: 'Black', value: '#000' },
										{ label: 'Dark Gray', value: '#666' },
										{ label: 'Light Gray', value: '#BBB' },
									]}
								/>
								<HStack spacing="1" color={textAccentColor}>
									<Icon as={FiClock} />
									<Text fontSize="xs" fontWeight="medium">
										Low stock
									</Text>
								</HStack>
							</Stack>
							<Stack flex="1">
								<ProductSizePicker
									defaultValue="32"
									options={[
										{ label: '32mm', value: '32' },
										{ label: '36mm', value: '36' },
										{ label: '40mm', value: '40' },
									]}
								/>
								<HStack spacing="1" color={textAccentColor}>
									<Icon as={RiRulerLine} />
									<Link href="#" fontSize="xs" fontWeight="medium" textDecoration="underline">
										View our sizing guide
									</Link>
								</HStack>
							</Stack>
						</Stack> */}
						<ProductPriceTag
							price={data?.rewards?.[0].points ?? 0}
							currency="POINTS"
							rootProps={{ fontSize: '4xl' }}
						/>
						<HStack spacing={{ base: '4', md: '8' }} align="flex-end" justify="space-evenly">
							{/* TODO Add Support for Quantity */}
							{/* <Box flex="1">
							<ProductQuantityPicker defaultValue={1} max={3} />
						</Box> */}
							<Box flex="1">
								<Button
									variant="outline"
									size="lg"
									fontSize="md"
									width="full"
									leftIcon={<Icon as={FiHeart} boxSize="4" />}
								>
									Favorite
								</Button>
							</Box>
						</HStack>
						<Button
							colorScheme="blue"
							size="lg"
							disabled={!isNumber(data?.rewards?.[0].points)}
							onClick={() => {
								// console.log('Add To Cart Clicked!');
								const product = data?.rewards?.[0];
								if (product) {
									if (user) {
										// TODO Validate Available Points
										addCartItem.mutate({ itemData: product, quantity: 1 });
										toast({
											// title: 'Account created.',
											description: `Added ${product.voucherName ?? ''} to your cart!`,
											status: 'success',
											duration: 5000,
											isClosable: true,
										});
									} else {
										navigate('/login');
									}
								} else {
									toast({
										title: 'Unable to add product!',
										description: `We encountered an issue while trying to add this product to your cart.`,
										status: 'error',
										duration: 5000,
										isClosable: true,
									});
								}
							}}
							isDisabled={!isNumber(data?.rewards?.[0].points)}
						>
							Add to cart
						</Button>
					</Stack>
				</Skeleton>
			</Stack>
		</Box>
	);
};
export default ProductDetailBlock;
