import {
	Box,
	Button,
	CircularProgress,
	Drawer,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Heading,
	HStack,
	Icon,
	Stack,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { FiPackage } from 'react-icons/fi';
import CartItem from './CartItem';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useCartManager } from '../../../domain/hooks';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { formatPrice } from '../../../domain/utils/PriceUtils';
import { NavLink } from 'react-router-dom';

const CartDrawer: React.FC = () => {
	const { setShowCart, showCart } = useContext(AppContext);
	const { getCart, removeCartItem, changeQuantity } = useCartManager();
	const { data: cartData, error: cartError } = getCart;
	const isFetching = useIsFetching({ queryKey: ['cart'] });
	const isMutating = useIsMutating({ mutationKey: ['cart'] });
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (isFetching > 0 || isMutating > 0) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [isFetching, isMutating]);

	useEffect(() => {
		if (cartError) {
			console.error(cartError);
			// TODO Display
		}
	}, [cartError]);

	return (
		<Box height="100vh" position={'absolute'} top={0} right={0}>
			<Drawer
				isOpen={showCart}
				onClose={() => {
					setShowCart(false);
				}}
				size="md"
			>
				<DrawerOverlay />
				<DrawerContent bg={useColorModeValue('white', 'gray.800')} overflowY="auto">
					<DrawerCloseButton size="lg" right={{ base: '4', md: '8' }} top="4" bg="inherit" />
					<Stack padding={{ base: '6', md: '10' }} height="full" spacing="8" overflowY="auto">
						<Heading size="md">Shopping Cart ({cartData?.items.length ?? '0'})</Heading>
						{loading && <CircularProgress isIndeterminate alignSelf={'center'} />}
						<Stack spacing={{ base: '8', md: '10' }}>
							{cartData?.items.map((item) => (
								<CartItem
									key={item.itemData.rewardId}
									{...item}
									onClickDelete={() => {
										removeCartItem.mutateAsync(item).catch((e: unknown) => {
											// TODO Show
											console.log(e);
										});
									}}
									onChangeQuantity={(qty) => {
										// console.log('onChangeQuantity', qty);
										changeQuantity
											.mutateAsync({ item: item, quantity: qty })
											.catch((e: unknown) => {
												// TODO Show
												console.log(e);
											});
									}}
								/>
							))}
						</Stack>
					</Stack>
					<Stack borderTopWidth="1px" px={{ base: '6', md: '10' }} py="4" spacing="5">
						<Stack>
							<HStack fontSize="xl" fontWeight="semibold">
								<Text flex="1">Subtotal:</Text>
								<Text>
									{formatPrice(cartData?.subtotal ?? 0, { locale: 'en-US', currency: 'POINTS' })}
								</Text>
							</HStack>
							<HStack spacing="2" color={useColorModeValue('gray.600', 'gray.400')}>
								<Icon as={FiPackage} />
								<Text>Shipping + taxes calculated at checkout</Text>
							</HStack>
						</Stack>
						<Button colorScheme="blue" size="lg" fontSize="md" as={NavLink} to={'/checkout'}>
							Checkout
						</Button>
					</Stack>
				</DrawerContent>
			</Drawer>
		</Box>
	);
};
export default CartDrawer;
