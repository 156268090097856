/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { useState } from 'react';

export type UseProductFilterStateProps<T> = {
	defaultValue: T | undefined;
	onSubmit?: (value: T) => void;
};

export function useProductFilterState<T>(props: UseProductFilterStateProps<T>) {
	const { defaultValue, onSubmit } = props;
	const [state, setState] = useState(defaultValue);
	return {
		canCancel: defaultValue !== state,
		value: state,
		onChange: setState,
		onReset() {
			setState(defaultValue);
		},
		onSubmit() {
			onSubmit?.(state as T);
		},
	};
}
