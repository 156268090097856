import {
	Box,
	BoxProps,
	Flex,
	FlexProps,
	IconButton,
	IconButtonProps,
	Stack,
	StackProps,
	Link,
	VStack,
	Heading,
	Text,
	Button,
} from '@chakra-ui/react';
import { KeenSliderOptions, useKeenSlider } from 'keen-slider/react'; // using version 6.6.10
import { forwardRef } from 'react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

export const Carousel = forwardRef<HTMLDivElement, FlexProps>(function Carousel(props, ref) {
	return (
		<Flex
			ref={ref}
			className="chakra-carousel"
			overflow="hidden"
			position="relative"
			userSelect="none"
			{...props}
		/>
	);
});

const CarouselSlide = (props: BoxProps) => (
	<Box
		className="chakra-carousel__slide"
		position="relative"
		overflow="hidden"
		width="100%"
		minH="100%"
		{...props}
	/>
);

const useCarousel = (options?: KeenSliderOptions) => {
	const defaultOptions = { selector: '.chakra-carousel__slide' };
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
	return useKeenSlider<HTMLDivElement>({ ...defaultOptions, ...options });
};

const CarouselIconButton = (props: IconButtonProps) => (
	<IconButton
		{...props}
		fontSize="2xl"
		isRound
		boxShadow="-4px 4px 6px rgba(24, 37, 170, .2)"
		bg={'white'}
		color={'secondary.500'}
		_hover={{
			bg: 'gray.200',
		}}
		_focus={{ boxShadow: 'inerhit' }}
		_focusVisible={{ boxShadow: 'outline' }}
		w={'40px'}
		h={'40px'}
	/>
);

const sampleEarningOptions = [
	{
		points: 100,
		heading: 'Gear Up',
		description: 'Earn 100 points for every $1 you spend at Pinnacle!',
		children: (
			<Link textDecor={'underline'} textTransform={'uppercase'}>
				Shop Now
			</Link>
		),
	},
	{
		points: 100,
		heading: 'Share & Earn',
		description:
			'Share or post your unique link to Peak Rewards and get 100 points for every friend that joins. (Up to 10 friends.).',
		children: (
			<VStack>
				<Link textDecor={'underline'} textTransform={'uppercase'}>
					copy my unique link
				</Link>
				<Text>— or —</Text>
				<Link textDecor={'underline'} textTransform={'uppercase'}>
					Get my unique code
				</Link>
			</VStack>
		),
	},
	{
		points: 100,
		heading: 'birthday rewards',
		description: 'Get 100 points on your Birthday!',
		children: (
			<Link textDecor={'underline'} textTransform={'uppercase'}>
				Claim Points
			</Link>
		),
	},
	{
		points: 100,
		heading: 'birthday rewards',
		description: 'Get 100 points on your Birthday!',
		children: (
			<Link textDecor={'underline'} textTransform={'uppercase'}>
				Claim Points
			</Link>
		),
	},
	{
		points: 100,
		heading: 'birthday rewards',
		description: 'Get 100 points on your Birthday!',
		children: (
			<Link textDecor={'underline'} textTransform={'uppercase'}>
				Claim Points
			</Link>
		),
	},
];

type ElementType<T extends readonly unknown[]> = T extends readonly (infer ElementType)[]
	? ElementType
	: never;

type TEarningOptions = ElementType<(typeof sampleEarningOptions)[]>;

interface GalleryProps {
	data: TEarningOptions;
	rootProps?: StackProps;
}

const Gallery: React.FC<GalleryProps> = (props) => {
	const { data, rootProps } = props;

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const [ref, slider] = useCarousel({
		loop: true,
		mode: 'free-snap',
		slides: {
			perView: data.length < 3 ? data.length : 3,
			spacing: 20,
		},
	});

	return (
		<Stack {...rootProps} minH={'200px'} w={'100%'}>
			<Box
				position="relative"
				sx={{
					_hover: {
						'> button': {
							display: 'inline-flex',
						},
					},
				}}
			>
				{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
				<Carousel ref={ref}>
					{data.map((item, i) => (
						<CarouselSlide
							key={`slide_${i.toString()}`}
							background={'url("/media/images/chart-bg.webp")'}
						>
							<VStack
								m="25px"
								p={'20px'}
								height={'calc(100% - 40px)'}
								justifyContent={'space-between'}
								alignItems={'center'}
								backgroundColor={'rgba(1, 44, 66, .75)'}
								textAlign={'center'}
							>
								<VStack
									w={'100px'}
									h={'100px'}
									gap={0}
									borderRadius={'50%'}
									border={'2px solid #fff'}
									backgroundColor={'#20513f'}
									display={'flex'}
									flexDirection={'column'}
									alignItems={'center'}
									justifyContent={'center'}
								>
									<Heading as={'span'} size={'lg'} fontWeight={'bold'}>
										{item.points}
									</Heading>
									<Text fontSize={'lg'} textTransform={'uppercase'}>
										Points
									</Text>
								</VStack>
								<Heading
									as="h1"
									size="xl"
									fontWeight="extrabold"
									textTransform="uppercase"
									maxW="md"
								>
									{item.heading}
								</Heading>
								<Text fontSize={{ md: '2xl' }} mt="4" maxW="sm">
									{item.description}
								</Text>
								{item.children}
							</VStack>
						</CarouselSlide>
					))}
				</Carousel>
				<CarouselIconButton
					pos="absolute"
					left="-20px"
					top="50%"
					transform="translateY(-50%)"
					// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
					onClick={() => slider.current?.prev()}
					icon={<IoChevronBackOutline />}
					aria-label="Previous Slide"
				/>
				<CarouselIconButton
					pos="absolute"
					right="-20px"
					top="50%"
					transform="translateY(-50%)"
					// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
					onClick={() => slider.current?.next()}
					icon={<IoChevronForwardOutline />}
					aria-label="Next Slide"
				/>
			</Box>
		</Stack>
	);
};

const EarningGallery: React.FC = () => {
	return (
		<Flex width={'100%'} mt={'20px'} position={'relative'} bg={'#fdf9f5'} justifyContent={'center'}>
			<VStack width={'100%'} maxW={'1280px'}>
				<Heading my={'20px'} color={'secondary.500'}>
					Start Earning
				</Heading>
				<Gallery data={sampleEarningOptions} />
				<Button
					textTransform="uppercase"
					variant="solid"
					color="white"
					bg="secondary.500"
					_hover={{ opacity: '0.8' }}
					size="lg"
					my={'20px'}
				>
					EARN POINTS
				</Button>
			</VStack>
		</Flex>
	);
};
export default EarningGallery;
