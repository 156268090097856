import { Box, Popover } from '@chakra-ui/react';
import { blueFilters, colorFilter, priceFilter, sizeFilter } from './_data';
import { useProductFilterState } from './useProductFilterState';
import { formatPrice } from '../../../domain/utils/PriceUtils';
import ProductSizePicker from './ProductSizePicker';
import ProductCheckboxFilter from './ProductCheckboxFilter';
import ProductColorPicker from './ProductColorPicker';
import { ProductFilterPopoverButton, ProductFilterPopoverContent } from './ProductFilterPopover';
import ProductPriceRangePicker from './ProductPriceRangePicker';

export const SizeFilterPopover = () => {
	const state = useProductFilterState({
		defaultValue: '32',
		onSubmit: console.log,
	});

	return (
		<Popover placement="bottom-start">
			<ProductFilterPopoverButton label="Size" />
			<ProductFilterPopoverContent
				isCancelDisabled={!state.canCancel}
				onClickApply={() => {
					state.onSubmit();
				}}
				onClickCancel={() => {
					state.onReset();
				}}
			>
				<ProductSizePicker
					hideLabel
					value={state.value}
					onChange={state.onChange}
					options={sizeFilter.options}
				/>
			</ProductFilterPopoverContent>
		</Popover>
	);
};

export const PriceFilterPopover = () => {
	const state = useProductFilterState({
		defaultValue: priceFilter.defaultValue,
		onSubmit: console.log,
	});
	return (
		<Popover placement="bottom-start">
			<ProductFilterPopoverButton label="Price" />
			<ProductFilterPopoverContent
				isCancelDisabled={!state.canCancel}
				onClickApply={() => {
					state.onSubmit();
				}}
				onClickCancel={() => {
					state.onReset();
				}}
			>
				<Box px="2" pt="2">
					<ProductPriceRangePicker
						step={10}
						min={priceFilter.min}
						max={priceFilter.max}
						value={state.value}
						onChange={state.onChange}
					/>
					<Box as="output" mt="2" fontSize="sm">
						{state.value?.map((v) => formatPrice(v)).join(' — ')}
					</Box>
				</Box>
			</ProductFilterPopoverContent>
		</Popover>
	);
};

export const CheckboxFilterPopover = () => {
	const state = useProductFilterState({
		defaultValue: blueFilters.defaultValue,
		onSubmit: console.log,
	});
	return (
		<Popover placement="bottom-start">
			<ProductFilterPopoverButton label="Brand" />
			<ProductFilterPopoverContent
				isCancelDisabled={!state.canCancel}
				onClickApply={() => {
					state.onSubmit();
				}}
				onClickCancel={() => {
					state.onReset();
				}}
			>
				<ProductCheckboxFilter
					hideLabel
					value={state.value}
					onChange={(v: React.SetStateAction<string[] | undefined>) => {
						state.onChange(v);
					}}
					options={blueFilters.options}
				/>
			</ProductFilterPopoverContent>
		</Popover>
	);
};

export const ColorFilterPopover = () => {
	const state = useProductFilterState({
		defaultValue: colorFilter.defaultValue,
		onSubmit: console.log,
	});
	return (
		<Popover placement="bottom-start">
			<ProductFilterPopoverButton label="Color" />
			<ProductFilterPopoverContent
				isCancelDisabled={!state.canCancel}
				onClickApply={() => {
					state.onSubmit();
				}}
				onClickCancel={() => {
					state.onReset();
				}}
			>
				<ProductColorPicker
					hideLabel
					value={state.value}
					onChange={state.onChange}
					options={colorFilter.options}
				/>
			</ProductFilterPopoverContent>
		</Popover>
	);
};
