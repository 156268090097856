import { Pagination as ArkPagination } from '@ark-ui/react';
import { Button, Center, IconButton, List, ListItem, Text } from '@chakra-ui/react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

export type PaginationProps = Omit<ArkPagination.RootProps, 'children'>;
interface IPagination {
	isLoading: boolean;
	props: PaginationProps;
}

export const Pagination: React.FC<IPagination> = ({ props, isLoading }) => {
	return (
		<ArkPagination.Root {...props}>
			<ArkPagination.Context>
				{({ pages, page }) => (
					<List display="flex" justifyContent="space-between">
						<ListItem>
							<ArkPagination.PrevTrigger asChild>
								<IconButton
									variant="secondary"
									isRound
									icon={<FiArrowLeft />}
									aria-label="Previous Page"
									isLoading={isLoading}
								/>
							</ArkPagination.PrevTrigger>
						</ListItem>

						<List display={{ base: 'none', md: 'flex' }} gap="1">
							{pages.map((p, index) =>
								p.type === 'page' ? (
									<ListItem key={index}>
										<ArkPagination.Item asChild {...p}>
											<Button
												variant="secondary"
												borderRadius="full"
												_selected={{ bg: 'gray.50', _dark: { bg: 'gray.800' } }}
												isLoading={isLoading}
											>
												{p.value}
											</Button>
										</ArkPagination.Item>
									</ListItem>
								) : (
									<ListItem key={index} alignItems="center" display="flex">
										<ArkPagination.Ellipsis index={index}>
											<Button
												variant="secondary"
												borderRadius="full"
												pointerEvents="none"
												width="10"
											>
												&#8230;
											</Button>
										</ArkPagination.Ellipsis>
									</ListItem>
								),
							)}
						</List>
						<ListItem as={Center} display={{ md: 'none' }}>
							<Text fontWeight="medium" color="fg.emphasized">
								Page {page} of {pages.length + 1}
							</Text>
						</ListItem>

						<ListItem>
							<ArkPagination.NextTrigger asChild>
								<IconButton
									variant="secondary"
									isRound
									icon={<FiArrowRight />}
									aria-label="Next Page"
									isLoading={isLoading}
								/>
							</ArkPagination.NextTrigger>
						</ListItem>
					</List>
				)}
			</ArkPagination.Context>
		</ArkPagination.Root>
	);
};
