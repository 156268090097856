import {
	Box,
	Flex,
	Img,
	Table,
	Thead,
	Tr,
	Th,
	Heading,
	VStack,
	Td,
	Tbody,
	Text,
} from '@chakra-ui/react';

interface ITierBenefits {
	backgroundImageSrc?: string;
}

const sampleTierData = [
	{
		tierName: 'base',
	},
	{
		tierName: 'ridge',
	},
	{
		tierName: 'crest',
	},
	{
		tierName: 'summit',
	},
];

const CheckIcon = (
	<img
		src="https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/how_it_work/Check_Icon.png"
		alt="Yes"
		height="24px;"
		width="24px;"
		style={{ margin: '0 auto' }}
	/>
);

const sampleBenefitData = [
	{
		benefitName: 'Points per Dollar Spent at Pinnacle',
		tiers: ['$1 = 1 Pt', '$2 = 2 Pt', '$3 = 3 Pt', '$4 = 4 Pt'],
	},
	{
		benefitName: 'Birthday Reward',
		benefitDescription: 'Earn 100 points on your birthday',
		tiers: [undefined, CheckIcon, CheckIcon, CheckIcon],
	},
	{
		benefitName: 'Early Release Sale',
		benefitDescription: 'Invitation only product releases',
		tiers: [undefined, undefined, CheckIcon, CheckIcon],
	},
	{
		benefitName: 'Discount January',
		benefitDescription: '10% off all products in January',
		tiers: [undefined, undefined, undefined, CheckIcon],
	},
];

const TierBenefits: React.FC<ITierBenefits> = ({ backgroundImageSrc }) => {
	return (
		<Flex as="section" minH="250px" position="relative" justifyContent={'center'}>
			<Flex p="40px" position="relative" zIndex={1} maxW="1280px" flex={1}>
				<VStack bg="white" borderRadius={5} flex={1}>
					<Heading textTransform="uppercase" as="h2" size="lg" color="secondary.500" my={'20px'}>
						level up to better benefits
					</Heading>
					<Table>
						<Thead bg="#d49283">
							<Tr>
								<Th
									textTransform="uppercase"
									textAlign="center"
									color="secondary.500"
									fontSize="medium"
								>
									tier benefits
								</Th>
								{sampleTierData.map((v, i) => (
									<Th
										textTransform="uppercase"
										textAlign="center"
										key={`tier_${i.toString()}`}
										color="secondary.500"
										fontSize="medium"
									>
										{v.tierName}
									</Th>
								))}
							</Tr>
						</Thead>
						<Tbody>
							{sampleBenefitData.map((v, i) => (
								<Tr key={`benefit_${i.toString()}`}>
									<Td bg="#dee5e2" color="secondary.500" textAlign="center" p={'15px'}>
										<VStack>
											<Heading as="h3" fontSize="large" mt={'2rem'} mb={'1rem'}>
												{v.benefitName}
											</Heading>
											{v.benefitDescription && (
												<Text mt={'0'} mb={'1rem'} fontSize={'medium'}>
													{v.benefitDescription}
												</Text>
											)}
										</VStack>
									</Td>
									{v.tiers.map((vt, it) => (
										<Td
											// display="flex"
											// flexDirection="row"
											key={`tierBenefit_${it.toString()}`}
											color="secondary.500"
											textAlign="center"
											fontSize="medium"
											// justifyContent={'center'}
											// alignContent={'center'}
											// alignItems={'center'}
											// center
										>
											{vt ? (typeof vt === 'object' ? vt : vt.toString()) : ''}
										</Td>
									))}
								</Tr>
							))}
						</Tbody>
					</Table>
				</VStack>
			</Flex>
			{backgroundImageSrc && (
				<Flex
					id="image-wrapper"
					position="absolute"
					insetX="0"
					insetY="0"
					w="full"
					h="full"
					overflow="hidden"
					align="center"
				>
					<Box position="relative" w="full" h="full">
						<Img
							src={backgroundImageSrc}
							alt="Tier Benefits Background"
							w="full"
							h="full"
							objectFit="cover"
							objectPosition="top bottom"
							position="absolute"
						/>
						{/* <Box position="absolute" w="full" h="full" bg="blackAlpha.600" /> */}
					</Box>
				</Flex>
			)}
		</Flex>
	);
};
export default TierBenefits;
