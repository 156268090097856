import { Button, HStack } from '@chakra-ui/react';

export interface ProductFilterActionButtonsProps {
	onClickCancel?: VoidFunction;
	isCancelDisabled?: boolean;
	onClickApply?: VoidFunction;
}

export const ProductFilterActionButtons: React.FC<ProductFilterActionButtonsProps> = (props) => {
	const { onClickApply, onClickCancel, isCancelDisabled } = props;
	return (
		<HStack spacing="2" justify="space-between">
			<Button size="sm" variant="tertiary" onClick={onClickCancel} isDisabled={isCancelDisabled}>
				Cancel
			</Button>
			<Button size="sm" colorScheme="blue" onClick={onClickApply}>
				Save
			</Button>
		</HStack>
	);
};
