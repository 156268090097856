import React from 'react';
import { Button, Flex, Heading, Img, Text } from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import SignInButton from '../authentication/SignInButton';

const WelcomeSidebar: React.FC = () => {
	/* hide the join button if we are on the join page */
	const location = useLocation();
	const showJoinButton = location.pathname !== '/join';

	return (
		<Flex
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			bg={'primary.500'}
			w={'100%'}
			minH={'480px'}
			px={'35px'}
			py={'45px'}
			color={'white'}
		>
			<Img
				src="https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/customer/Mountain_img_2x.png"
				alt="Logo graphic"
				maxW={'100%'}
				objectFit={'contain'}
			/>
			<Heading as={'h2'} textTransform={'uppercase'}>
				WELCOME TO PEAK REWARDS
			</Heading>
			<Text mt={4} mb={4} fontSize={'18px'}>
				Register for free and start earning points you can spend on your next adventure.
			</Text>
			{showJoinButton && (
				<Button
					as={NavLink}
					to={'/join'}
					size="lg"
					textTransform={'uppercase'}
					textColor={'black'}
					mx={'20px'}
					backgroundColor="#d49283"
					_hover={{ backgroundColor: '#c77d6c' }}
				>
					JOIN NOW
				</Button>
			)}
			<Text textTransform={'uppercase'} mt={'40px'} fontSize={'16px'}>
				ALREADY A PEAK REWARDS MEMBER?
			</Text>
			<SignInButton
				mt={4}
				colorScheme="white"
				variant="outline"
				px="8"
				rounded="5"
				size="lg"
				fontSize="md"
				textTransform="uppercase"
			/>
		</Flex>
	);
};

export default WelcomeSidebar;
