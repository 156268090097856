import { useMutation, useQuery } from '@tanstack/react-query';
import {
	IAddress,
	IApiLoginRequestParameters,
	IApiLoginResponse,
	IApiManager,
	IGetMemberBalanceRequestParameters,
	IGetMemberBalanceResponse,
	// IGetProductsRequestParameters,
	// IGetProductsResponse,
	IMember,
	IRedeemRewardRequest,
	IRedeemRewardResponse,
} from '../models';
import { getCookie } from '../utils/CookieUtils';
import { useContext, useState } from 'react';
import React from 'react';
import { AppContext } from '../../data/globalstate';
import { objectToUrlParams } from '../utils/GeneralUtils';

export const useApiManager = (): IApiManager => {
	// TODO Move base url to config?
	const source = 'https://localhost:44335/api';
	// const [rewardsCurrentPage, setRewardsCurrentPage] = useState<number>(1);
	// const [getProductsParams, setGetProductsParams] = useState<IGetProductsRequestParameters>();
	const [getBalanceParams, setGetBalanceParams] = useState<IGetMemberBalanceRequestParameters>();
	const { user } = useContext(AppContext);
	// const [getRewardsParams, setGetRewardsParams] = useState<IGetRewardsRequestParameters>({
	// 	PageIndex: 1,
	// 	PageSize: 9,
	// });
	// const queryClient = useQueryClient();
	// const rewardsUrlParams = objectToUrlParams(getRewardsParams);

	// useEffect(() => {
	// 	console.log('useApiManager::getRewardsParams::changed', getRewardsParams);
	// 	// if (!getRewardsParams) {
	// 	// 	return;
	// 	// }

	// 	// void queryClient.invalidateQueries({
	// 	// 	queryKey: ['rewards', 'get', getRewardsParams.PageIndex],
	// 	// 	exact: true,
	// 	// 	refetchType: 'all',
	// 	// });

	// 	void queryClient.refetchQueries({
	// 		queryKey: ['rewards', 'get', rewardsUrlParams],
	// 		exact: true,
	// 	});
	// 	// void getRewardsFn();
	// }, [getRewardsParams]);

	// const getRewards = useQuery<IGetRewardsResponse>(
	// 	{
	// 		queryKey: ['rewards', 'get', rewardsUrlParams],
	// 		// queryFn: () => getRewardsFn(),
	// 		queryFn: useCallback(() => {
	// 			console.log('useApiManager::getRewards');
	// 			console.log(getRewardsParams);

	// 			const token = getCookie('accessToken');
	// 			let url = source + '/Reward';

	// 			// if (getRewardsParams) {
	// 			// const urlParams = decodeURI(new URLSearchParams(getRewardsParams).toString());
	// 			// const urlParams = objectToUrlParams(getRewardsParams);
	// 			// console.log(urlParams);
	// 			url = url + '?' + rewardsUrlParams;
	// 			// }

	// 			// console.log(url);

	// 			const options: RequestInit = {
	// 				method: 'GET',
	// 				headers: {
	// 					'Accept': 'application/json',
	// 					'Content-Type': 'application/json',
	// 					'Authorization': 'Bearer ' + (token ? token : ''),
	// 					'Access-Control-Allow-Origin': '*',
	// 				},
	// 				// body: JSON.stringify({
	// 				// 	AccountNumber: data.accountNumber,
	// 				// 	PromotionName: data.promotionName,
	// 				// }),
	// 			};

	// 			return new Promise<IGetRewardsResponse>((resolve, reject) => {
	// 				// const response = await fetch(url, options);
	// 				fetch(url, options)
	// 					.then(async (response) => {
	// 						const data = (await response.json()) as IGetRewardsResponse;
	// 						// console.log(response.status);
	// 						// console.log(data);
	// 						resolve(data);
	// 					})
	// 					.catch((e: unknown) => {
	// 						reject(e);
	// 					});
	// 			});
	// 		}, [getRewardsParams]),
	// 		// queryFn: () => {
	// 		// 	console.log('useApiManager::getRewards');
	// 		// 	console.log(getRewardsParams);

	// 		// 	const token = getCookie('accessToken');
	// 		// 	let url = source + '/Reward';

	// 		// 	if (getRewardsParams) {
	// 		// 		const urlParams = new URLSearchParams(JSON.stringify(getRewardsParams)).toString();
	// 		// 		// console.log(urlParams);
	// 		// 		url = url + '?' + urlParams;
	// 		// 	}

	// 		// 	console.log(url);

	// 		// 	const options: RequestInit = {
	// 		// 		method: 'GET',
	// 		// 		headers: {
	// 		// 			'Accept': 'application/json',
	// 		// 			'Content-Type': 'application/json',
	// 		// 			'Authorization': 'Bearer ' + (token ? token : ''),
	// 		// 			'Access-Control-Allow-Origin': '*',
	// 		// 		},
	// 		// 		// body: JSON.stringify({
	// 		// 		// 	AccountNumber: data.accountNumber,
	// 		// 		// 	PromotionName: data.promotionName,
	// 		// 		// }),
	// 		// 	};

	// 		// 	return new Promise((resolve, reject) => {
	// 		// 		// const response = await fetch(url, options);
	// 		// 		fetch(url, options)
	// 		// 			.then(async (response) => {
	// 		// 				const data = (await response.json()) as IGetRewardsResponse;
	// 		// 				// console.log(response.status);
	// 		// 				// console.log(data);
	// 		// 				resolve(data);
	// 		// 			})
	// 		// 			.catch((e: unknown) => {
	// 		// 				reject(e);
	// 		// 			});
	// 		// 	});
	// 		// },
	// 	},
	// 	queryClient,
	// );

	// useEffect(() => {
	// 	setGetRewardsParams({
	// 		...getRewardsParams,
	// 		PageIndex: rewardsCurrentPage,
	// 	});
	// }, [rewardsCurrentPage]);

	React.useEffect(() => {
		// Get Users Points Balance
		if (user) {
			setGetBalanceParams({ membershipCode: user.burstId });
		} else {
			setGetBalanceParams(undefined);
		}
	}, [user]);

	return {
		getApiUrl: () => {
			return source;
		},
		login: useMutation({
			mutationFn: async (data: IApiLoginRequestParameters) => {
				const token = getCookie('accessToken');
				const url = source + '/login';
				const options: RequestInit = {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + (token ? token : ''),
						'Access-Control-Allow-Origin': '*',
					},
					body: JSON.stringify({
						AccountNumber: data.accountNumber,
						PromotionName: data.promotionName,
					}),
				};

				return new Promise((resolve, reject) => {
					fetch(url, options)
						.then(async (response) => {
							const data = (await response.json()) as IApiLoginResponse;
							// console.log(response.status);
							// console.log(data);
							resolve(data.member);
						})
						.catch((e: unknown) => {
							reject(e);
						});
				});
			},
			scope: {
				id: 'api:login',
			},
		}),
		register: useMutation({
			mutationFn: async (data: {
				userName: string;
				password: string;
				firstName: string;
				lastName: string;
				address: IAddress;
			}) => {
				// console.log(data.userName + data.password + data.firstName + data.lastName);
				// console.log(data.address.city);
				const url = source + '/register';
				const token = getCookie('accessToken');
				const member: IMember = {
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.userName,
					address: data.address,
					campaign: null,
					id: null,
				};
				const options: RequestInit = {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + (token ? token : ''),
						'Access-Control-Allow-Origin': '*',
					},
					body: JSON.stringify(member),
				};
				const response = await fetch(url, options);
				// console.log(response);
				const bu = (await response.json()) as { member: IMember };
				return bu.member;
			},
			scope: {
				id: 'api:register',
			},
		}),
		// setGetProductsParams,
		// getProducts: useQuery({
		// 	queryKey: ['products', 'get'],
		// 	queryFn: () => {
		// 		// console.log('useApiManager::getProducts');
		// 		const token = getCookie('accessToken');
		// 		let url = source + '/Product/GetProducts';

		// 		if (getProductsParams) {
		// 			const urlParams = new URLSearchParams(JSON.stringify(getProductsParams)).toString();
		// 			// console.log(urlParams);
		// 			url = url + '?' + urlParams;
		// 		}

		// 		// console.log(url);

		// 		const options: RequestInit = {
		// 			method: 'GET',
		// 			headers: {
		// 				'Accept': 'application/json',
		// 				'Content-Type': 'application/json',
		// 				'Authorization': 'Bearer ' + (token ? token : ''),
		// 				'Access-Control-Allow-Origin': '*',
		// 			},
		// 			// body: JSON.stringify({
		// 			// 	AccountNumber: data.accountNumber,
		// 			// 	PromotionName: data.promotionName,
		// 			// }),
		// 		};

		// 		return new Promise((resolve, reject) => {
		// 			// const response = await fetch(url, options);
		// 			fetch(url, options)
		// 				.then(async (response) => {
		// 					const data = (await response.json()) as IGetProductsResponse;
		// 					// console.log(response.status);
		// 					// console.log(data);
		// 					resolve(data);
		// 				})
		// 				.catch((e: unknown) => {
		// 					reject(e);
		// 				});
		// 		});
		// 	},
		// }),
		getMemberBalance: useQuery({
			queryKey: ['memberBalance'],
			queryFn: () => {
				// console.log('useApiManager::getMemberBalance');
				const token = getCookie('accessToken');
				let url = source + '/Membership/Balance';

				if (!getBalanceParams) {
					return Promise.reject('No Member Code!');
				}

				// if (getBalanceParams) {
				// const urlParams = new URLSearchParams(JSON.stringify(getBalanceParams)).toString();
				// console.log(urlParams);
				// url = url + '?' + urlParams;
				url = url + '?' + objectToUrlParams(getBalanceParams);
				// }

				// console.log(url);

				const options: RequestInit = {
					method: 'GET',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + (token ? token : ''),
						'Access-Control-Allow-Origin': '*',
					},
					// body: JSON.stringify({
					// 	AccountNumber: data.accountNumber,
					// 	PromotionName: data.promotionName,
					// }),
				};

				return new Promise((resolve, reject) => {
					// const response = await fetch(url, options);
					fetch(url, options)
						.then(async (response) => {
							const data = (await response.json()) as IGetMemberBalanceResponse;
							// console.log(response.status);
							// console.log(data);
							resolve(data);
						})
						.catch((e: unknown) => {
							reject(e);
						});
				});
			},
		}),
		setGetBalanceParams,
		// getRewards,
		// setGetRewardsParams,
		// rewardsCurrentPage,
		// setRewardsCurrentPage,
		redeemReward: useMutation({
			mutationFn: async (data: IRedeemRewardRequest) => {
				// console.log(data.userName + data.password + data.firstName + data.lastName);
				// console.log(data.address.city);
				const url = source + '/Reward';
				const token = getCookie('accessToken');
				// const member: IMember = {
				// 	firstName: data.firstName,
				// 	lastName: data.lastName,
				// 	email: data.userName,
				// 	address: data.address,
				// 	campaign: null,
				// 	id: null,
				// };
				const options: RequestInit = {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + (token ? token : ''),
						'Access-Control-Allow-Origin': '*',
					},
					body: JSON.stringify(data),
				};
				const response = await fetch(url, options);
				// console.log(response);
				const bu = (await response.json()) as IRedeemRewardResponse;
				return bu;
			},
			scope: {
				id: 'api:redeemReward',
			},
		}),
	};
};
