import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import HeroImageBackground from '../components/hero/HeroImageBackground';
import PageHeaderCenteredOnAccent from '../components/header/PageHeaderCenteredOnAccent';
import PerksThreeColumn from '../components/PerksThreeColumn';
import PageHeaderCentered from '../components/header/PageHeaderCentered';
import TierBenefits from '../components/TierBenefits';
import FooterTwoColumnBackground from '../components/FooterTwoColumnBackground';
import MapTrail from '../components/MapTrail';
import { ScrollRestoration } from 'react-router-dom';
// import OverflowCarousel from '../components/OverflowCarousel';

const HomePage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
	<Box textAlign="center" fontSize="xl" ref={ref}>
		<ScrollRestoration />
		<CartBar />
		<NavigationBar />
		{/* <OverflowCarousel /> */}
		<HeroImageBackground
			// backgroundImageSrc="https://images.unsplash.com/photo-1590650153855-d9e808231d41?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2250&q=80"
			backgroundImageSrc="/media/images/HeaderImg_withgreen_2x.webp"
		/>
		<PageHeaderCenteredOnAccent />
		<PerksThreeColumn />
		<PageHeaderCentered />
		<TierBenefits backgroundImageSrc="/media/images/chart-bg.webp" />
		<MapTrail />
		<FooterTwoColumnBackground />
	</Box>
));

export const AnimatedHome = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <HomePage ref={ref} />),
);

export default HomePage;
