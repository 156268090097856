import { useFormikContext } from 'formik';
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

interface IFirstLastName {
	firstName: Partial<HTMLInputElement>;
	firstNameLabel: string;
	lastName: Partial<HTMLInputElement>;
	lastNameLabel: string;
	required: boolean;
	size: string;
	borderColor?: string;
}

export interface IFirstLastNameValues {
	firstName?: string;
	lastName?: string;
}

export const initialFirstLastNameValues = {
	firstName: undefined,
	lastName: undefined,
};

const FirstLastName: React.FC<IFirstLastName> = ({
	required,
	firstName,
	firstNameLabel,
	lastName,
	lastNameLabel,
	size,
	borderColor,
}) => {
	const { values, handleChange, handleBlur, errors, touched } =
		useFormikContext<IFirstLastNameValues>();

	return (
		<>
			<FormControl isRequired={required} isInvalid={Boolean(touched.firstName && errors.firstName)}>
				<FormLabel htmlFor="firstName" id="firstNameLabel">
					{firstNameLabel}
				</FormLabel>
				<Input
					id="firstName"
					name={firstName.name ? firstName.name : 'firstName'}
					type="text"
					value={values.firstName}
					required={required}
					onChange={handleChange}
					onBlur={handleBlur}
					size={size ? size : 'md'}
					borderColor={borderColor}
				/>
				{errors.firstName && touched.firstName && (
					<FormErrorMessage>{errors.firstName}</FormErrorMessage>
				)}
			</FormControl>
			<FormControl isRequired={required} isInvalid={Boolean(touched.lastName && errors.lastName)}>
				<FormLabel htmlFor="lastName" id="lastNameLabel">
					{lastNameLabel}
				</FormLabel>
				<Input
					id="lastName"
					name={lastName.name ? lastName.name : 'lastName'}
					type="text"
					value={values.lastName}
					required={required}
					onChange={handleChange}
					onBlur={handleBlur}
					size={size ? size : 'md'}
					borderColor={borderColor}
				/>
				{errors.lastName && touched.lastName && (
					<FormErrorMessage>{errors.lastName}</FormErrorMessage>
				)}
			</FormControl>
		</>
	);
};

export default FirstLastName;
