import * as React from 'react';
import { ChakraProvider, extendTheme, Skeleton, Stack, UseToastOptions } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
// import { HyperThemeEditor, ThemeEditorProvider } from '@hypertheme-editor/chakra-ui'; //NOTE: Turned off until we have full version due to font overriding.
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AnimatedHome } from '../presentation/pages/HomePage';
import { AnimatedEarnPointsPage } from '../presentation/pages/EarnPointsPage';
import { AnimatedJoinPage } from '../presentation/pages/JoinPage';
import { AppContext, IAppConfiguration } from '../data/globalstate';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ToastNotification from '../presentation/components/notifications/ToastNotification';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AnimatedRewardsPage } from '../presentation/pages/RewardsPage';
import { AnimatedCartPage } from '../presentation/components/cart/CartPage';
import { AnimatedContactUsPage } from '../presentation/pages/ContactUsPage';
import { AnimatedHowItWorksPage } from '../presentation/pages/HowItWorks';
import { IUser } from '../domain/models';
import { AnimatedAuthPage } from '../presentation/components/authentication/AuthPage';
import AuthHandler from '../presentation/components/authentication/AuthHandler';
import { AnimatedProductDetailPage } from '../presentation/pages/ProductDetailPage';
import { AnimatedOrderSummaryPage } from '../presentation/pages/OrderSummaryPage';

const AnimatedSkeleton = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
		<Stack ref={ref}>
			<Skeleton height="20px" />
			<Skeleton height="20px" />
			<Skeleton height="20px" />
		</Stack>
	)),
);

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<AnimatedHome
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/earn-points',
		element: (
			<AnimatedEarnPointsPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/contact-us',
		element: (
			<AnimatedContactUsPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/join',
		element: (
			<AnimatedJoinPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/rewards',
		element: (
			<AnimatedRewardsPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/cart',
		element: (
			<AnimatedCartPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/how-it-works',
		element: (
			<AnimatedHowItWorksPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/login',
		element: (
			<AnimatedAuthPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/product/:productId',
		element: (
			<AnimatedProductDetailPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/checkout',
		element: (
			<AnimatedOrderSummaryPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
]);

const queryClient = new QueryClient();

const themeExtended = extendTheme({
	fonts: {
		heading: `'Open Sans', sans-serif`,
		body: `'Open Sans', sans-serif`,
		mono: `'Open Sans', sans-serif`,
	},
	colors: {
		primary: {
			50: '#62c19e',
			100: '#44ac86',
			200: '#3d9a78',
			300: '#36886a',
			400: '#2e765b',
			500: 'rgb(32, 81, 63)', // #20513F
			600: '#122c23',
			700: '#0a1a14',
			800: '#030806',
			900: '#000000',
		},
		secondary: {
			50: '#04a3f3',
			100: '#0381c1',
			200: '#0270a8',
			300: '#02608e',
			400: '#024f75',
			500: 'rgb(1, 45, 67)', // #012D43
			600: '#000b11',
			700: '#000000',
			800: '#000000',
			900: '#000000',
		},
	},
});

const initApp = (
	setLoading: React.Dispatch<boolean>,
	setAppConfiguration: React.Dispatch<IAppConfiguration | undefined>,
) => {
	console.log('initApp');
	setLoading(true);
	// TODO Read Configs and inject.

	// Processings.......

	setAppConfiguration({
		cognitoClientId: '3n122fcl8khvplthr87odfjsfr',
		cognitoUserPoolId: 'us-east-1_TsZ30sPqw',
		useCartPage: true,
		useLoginPage: true,
	});

	// themeExtended.fonts = {
	// 	heading: `'Open Sans', sans-serif`,
	// 	body: `'Open Sans', sans-serif`,
	// 	mono: `'Open Sans', sans-serif`,
	// };

	// Hide Loader => App Ready
	setTimeout(() => {
		setLoading(false);
	}, 1000);
};

// const Main: React.FC = () => (
// 	<Box textAlign="center" fontSize="xl">
// 		<Grid minH="100vh" p={3}>
// 			<ColorModeSwitcher justifySelf="flex-end" />
// 			<VStack spacing={8}>
// 				{/* <Logo h="40vmin" pointerEvents="none" /> */}
// 				<Text>
// 					Edit <Code fontSize="xl">src/App.tsx</Code> and save to reload.
// 				</Text>
// 				<Link
// 					color="teal.500"
// 					href="https://chakra-ui.com"
// 					fontSize="2xl"
// 					target="_blank"
// 					rel="noopener noreferrer"
// 				>
// 					Learn Chakra
// 				</Link>
// 			</VStack>
// 		</Grid>
// 	</Box>
// );

// const AnimatedMain = motion(
// 	React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
// 		<Box textAlign="center" fontSize="xl" ref={ref}>
// 			<CartBar />
// 			<NavigationBar />
// 			<HeroImageBackground
// 				// backgroundImageSrc="https://images.unsplash.com/photo-1590650153855-d9e808231d41?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2250&q=80"
// 				backgroundImageSrc="https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/HeaderImg_withgreen_2x.png"
// 			/>
// 			<PageHeaderCenteredOnAccent />
// 			<FeaturesThreeColumns />
// 		</Box>
// 	)),
// );

const App: React.FC = () => {
	const [appConfiguration, setAppConfiguration] = React.useState<IAppConfiguration>();
	const [loading, setLoading] = React.useState<boolean>(true);
	const [showCart, setShowCart] = React.useState<boolean>(false);
	const [showAuth, setShowAuth] = React.useState<boolean>(false);
	const [toast, setToast] = React.useState<UseToastOptions>({});
	const [user, setUser] = React.useState<IUser | undefined>(undefined);

	React.useEffect(() => {
		// console.log(loading);
		initApp(setLoading, setAppConfiguration);
		// setToast({ title: 'Test', status: 'loading', position: 'top', id: 'test1', isClosable: false });
		// setTimeout(() => {
		// 	setToast({
		// 		title: 'Test2',
		// 		status: 'success',
		// 		position: 'bottom',
		// 		id: 'test2',
		// 		isClosable: true,
		// 	});
		// }, 1000);
	}, []);

	return (
		<AppContext.Provider
			value={{
				appConfiguration,
				setAppConfiguration,
				showCart,
				setShowCart,
				showAuth,
				setShowAuth,
				toast,
				setToast,
				user,
				setUser,
			}}
		>
			<QueryClientProvider client={queryClient}>
				<AuthHandler />
				<ChakraProvider
					theme={themeExtended}
					toastOptions={{ defaultOptions: { position: 'bottom' } }}
				>
					<AnimatePresence>
						{loading && (
							<AnimatedSkeleton
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{
									opacity: 0,
								}}
								transition={{ duration: 1 }}
							/>
						)}
						{!loading && <RouterProvider router={router} />}
					</AnimatePresence>
					{/* <ThemeEditorProvider>
					<HyperThemeEditor pos="fixed" bottom={4} right={2} aria-label="Theme Editor" />
				</ThemeEditorProvider> */}
					{/* <AnimatePresence>
					{loading && (
						<AnimatedSkeleton
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{
								opacity: 0,
							}}
							transition={{ duration: 1 }}
						/>
					)}
					{!loading && (
						<AnimatedHome
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{
								opacity: 0,
							}}
							transition={{ duration: 1 }}
						/>
					)}
				</AnimatePresence> */}
					<ToastNotification />
				</ChakraProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</AppContext.Provider>
	);
};

export default App;
