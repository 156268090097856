export interface FormatPriceOptions {
	locale?: string;
	currency?: string;
}

export function formatPrice(value: number, opts: { locale?: string; currency?: string } = {}) {
	const { locale = 'en-US', currency = 'USD' } = opts;
	if (currency.toUpperCase() === 'POINTS') {
		const formatter = new Intl.NumberFormat(locale, {
			style: 'decimal',
			maximumFractionDigits: 3,
		});
		return `${formatter.format(value)} POINTS`;
	} else {
		const formatter = new Intl.NumberFormat(locale, {
			currency,
			style: 'currency',
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	}
}
