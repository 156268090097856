import { useFormikContext } from 'formik';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Textarea,
	TextareaProps,
} from '@chakra-ui/react';

interface IComments {
	comments: Partial<HTMLTextAreaElement>;
	commentsLabel: string;
	textAreaProps?: TextareaProps;
}

export interface ICommentsValues {
	comments?: string;
}

export const initialCommentsValues = {
	comments: undefined,
};

const CommentsTextarea: React.FC<IComments> = ({ comments, commentsLabel, textAreaProps }) => {
	const { values, handleChange, handleBlur, errors, touched } = useFormikContext<ICommentsValues>();

	return (
		<>
			<FormControl
				isRequired={textAreaProps?.isRequired}
				isInvalid={Boolean(errors.comments && touched.comments)}
			>
				<FormLabel htmlFor="comments" id="commentsLabel">
					{commentsLabel}
				</FormLabel>
				<Textarea
					{...textAreaProps}
					id="comments"
					name={comments.name ? comments.name : 'comments'}
					// required={required}
					onChange={handleChange}
					onBlur={handleBlur}
					// borderColor={borderColor}
					width="420px"
					height="320px"
				>
					{values.comments}
				</Textarea>
				{errors.comments && touched.comments && (
					<FormErrorMessage>{errors.comments}</FormErrorMessage>
				)}
			</FormControl>
		</>
	);
};

export default CommentsTextarea;
