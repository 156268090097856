import {
	AspectRatio,
	Box,
	HStack,
	Image,
	Skeleton,
	Stack,
	Text,
	VStack,
	Link,
	IconButton,
	useToast,
} from '@chakra-ui/react';
import { FiEye, FiHeart } from 'react-icons/fi';
import { IProductQuickViewModalProps } from './ProductQuickViewModal';
import { IRewardType } from '../../../domain/models';
import { NavLink, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useCartManager } from '../../../domain/hooks';
import { FaShoppingCart } from 'react-icons/fa';

interface Props {
	product: IRewardType;
	quickViewProps: IProductQuickViewModalProps;
	setQuickViewProps: React.Dispatch<IProductQuickViewModalProps>;
}

const ProductCard: React.FC<Props> = ({ product, setQuickViewProps }) => {
	const { user } = useContext(AppContext);
	const navigate = useNavigate();
	const { addCartItem } = useCartManager();
	const toast = useToast();
	let primaryImageUrl = product.imageURL;
	let primaryAltText = product.voucherName;

	if (!primaryImageUrl) {
		const match = product.imageAlternatives?.filter((v) => {
			return v.isPrimary;
		});
		if (match) {
			primaryImageUrl = match[0].url;
			primaryAltText = match[0].altText;
		}
	}

	// FIXME Placeholder Image for products with no images at all.

	return (
		<Stack spacing="4">
			<Box position="relative" className="group">
				<Link as={NavLink} to={product.rewardId ? `/product/${product.rewardId}` : '#'}>
					<AspectRatio ratio={1}>
						<Image
							src={primaryImageUrl ?? undefined}
							alt={primaryAltText ?? 'Unknown'}
							draggable="false"
							fallback={<Skeleton />}
							borderRadius="md"
						/>
					</AspectRatio>
				</Link>
				<VStack
					position="absolute"
					className="container"
					top="0"
					left="0"
					gap={0}
					w={'100%'}
					h={'100%'}
					justifyContent={'space-between'}
				>
					<HStack alignSelf={'stretch'} justifyContent={'center'}>
						{product.voucherTypeSubCategory === 'SWEEPSTAKE' && (
							<HStack
								justifyContent={'space-between'}
								textColor={'white'}
								alignSelf={'stretch'}
								flex={1}
								alignItems={'center'}
								px={2}
								pt={2}
								backgroundColor={'secondary.500'}
								borderRadius={'4px 4px 0 0'}
							>
								<Text textTransform={'uppercase'}>sweepstakes</Text>
								<HStack flex={1} gap={0} justifyContent={'flex-end'} position={'relative'}>
									<VStack
										w={'64px'}
										h={'64px'}
										borderRadius={'32px'}
										backgroundColor={'primary.500'}
										color={'white'}
										gap={0}
										justifyContent={'center'}
										position={'absolute'}
										top={0}
										right={'45%'}
										border={'2px solid white'}
									>
										<Text fontSize={'medium'} fontWeight={'bold'}>
											{product.points?.toLocaleString('en-US')}
										</Text>
										<Text fontSize={'small'}>Points</Text>
									</VStack>
									<IconButton
										as={FiEye}
										aria-label="View details"
										onClick={() => {
											setQuickViewProps({ isShown: true, product: product });
										}}
										w={'24px'}
										h={'24px'}
										variant={'ghost'}
									/>
								</HStack>
							</HStack>
							// <HStack flex={1} gap={0} justifyContent={'center'} position={'relative'}>
							// 	<HStack backgroundColor={'secondary.500'} p={2} px={5} alignSelf={'stretch'}>
							// 		<Text textTransform={'uppercase'}>sweepstakes</Text>
							// 		<IconButton
							// 			as={FiEye}
							// 			aria-label="View details"
							// 			onClick={() => {
							// 				setQuickViewProps({ isShown: true, product: product });
							// 			}}
							// 			w={'24px'}
							// 			h={'24px'}
							// 		/>
							// 	</HStack>
							// 	<VStack
							// 		w={'64px'}
							// 		h={'64px'}
							// 		borderRadius={'32px'}
							// 		backgroundColor={'primary.500'}
							// 		color={'white'}
							// 		gap={0}
							// 		justifyContent={'center'}
							// 		position={'absolute'}
							// 		top={3}
							// 		right={3}
							// 		border={'2px solid white'}
							// 	>
							// 		<Text fontSize={'medium'} fontWeight={'bold'}>
							// 			{product.points?.toLocaleString('en-US')}
							// 		</Text>
							// 		<Text fontSize={'small'}>Points</Text>
							// 	</VStack>
							// </HStack>
						)}
						{product.voucherTypeSubCategory !== 'SWEEPSTAKE' && (
							<HStack
								justifyContent={'space-between'}
								textColor={'secondary.500'}
								alignSelf={'stretch'}
								flex={1}
								alignItems={'center'}
								px={2}
								pt={2}
							>
								{product.quantity === null && <Text textTransform={'uppercase'}>sold out</Text>}
								{product.quantity !== null && (
									<Text textTransform={'uppercase'} fontSize={'sm'} fontWeight={'bold'}>
										{product.quantity > 0 ? `${product.quantity.toString()} available` : 'sold out'}
									</Text>
								)}
								<HStack flex={1} gap={0} justifyContent={'flex-end'} position={'relative'}>
									<VStack
										w={'64px'}
										h={'64px'}
										borderRadius={'32px'}
										backgroundColor={'primary.500'}
										color={'white'}
										gap={0}
										justifyContent={'center'}
										position={'absolute'}
										top={0}
										right={'50%'}
										border={'2px solid white'}
									>
										<Text fontSize={'medium'} fontWeight={'bold'}>
											{product.points?.toLocaleString('en-US')}
										</Text>
										<Text fontSize={'small'}>Points</Text>
									</VStack>
									<IconButton
										as={FiHeart}
										aria-label="Add to wishlist"
										onClick={() => {
											// TODO Wishlist
											console.log('Add to Wishlist', product);
										}}
										w={'24px'}
										h={'24px'}
										variant={'ghost'}
									/>
									<IconButton
										as={FiEye}
										aria-label="View details"
										onClick={() => {
											setQuickViewProps({ isShown: true, product: product });
										}}
										w={'24px'}
										h={'24px'}
										variant={'ghost'}
									/>
								</HStack>
							</HStack>
						)}
					</HStack>
					<VStack gap={0} px={2} alignSelf={'stretch'} alignItems={'flex-start'} pb={2}>
						<Link
							as={NavLink}
							to={product.rewardId ? `/product/${product.rewardId}` : '#'}
							color={'#000'}
						>
							<Text size={'md'} fontWeight={'bold'} color={'#000'} textAlign={'left'}>
								{product.voucherName}
							</Text>
						</Link>
						{/* <Link
							textTransform={'uppercase'}
							textDecoration={'underline'}
							alignSelf={'flex-end'}
							onClick={() => {
								if (user) {
									// TODO Validate Available Points
									addCartItem.mutate({ itemData: product, quantity: 1 });
									toast({
										// title: 'Account created.',
										description: `Added ${product.voucherName ?? ''} to your cart!`,
										status: 'success',
										duration: 5000,
										isClosable: true,
									});
								} else {
									navigate('/login');
								}
							}}
						>
							redeem &gt;
						</Link> */}
						<IconButton
							aria-label="Add to Cart"
							icon={<FaShoppingCart />}
							alignSelf={'flex-end'}
							variant={'ghost'}
							onClick={() => {
								if (user) {
									// TODO Validate Available Points
									addCartItem.mutate({ itemData: product, quantity: 1 });
									toast({
										// title: 'Account created.',
										description: `Added ${product.voucherName ?? ''} to your cart!`,
										status: 'success',
										duration: 5000,
										isClosable: true,
									});
								} else {
									navigate('/login');
								}
							}}
						/>
					</VStack>
				</VStack>

				{/* <Box
					opacity={0}
					transition="opacity 0.1s"
					position="absolute"
					className="container"
					bottom="3"
					left="3"
					_groupHover={{ opacity: 1 }}
				>
					<HStack spacing="3">
						<ProductCardButton
							aria-label="Add to favourite"
							icon={<Icon as={FiHeart} boxSize="5" />}
							// TODO Implement Favorite Function
						/>
						<ProductCardButton
							aria-label="View details"
							icon={<Icon as={FiEye} boxSize="5" />}
							onClick={() => {
								setQuickViewProps({ isShown: true, product: product });
							}}
						/>
					</HStack>
				</Box> */}
			</Box>
			{/* <Link to={product.rewardId ? `/product/${product.rewardId}` : '#'}>
				<Stack spacing="1">
					<HStack justifyContent="space-between">
						<Text
							color={useColorModeValue('black', 'white')}
							fontSize="sm"
							fontWeight="semibold"
							letterSpacing="wider"
							textTransform="uppercase"
						>
							{product.voucherName}
						</Text>
						<ProductPriceTag
							currency={'POINTS'}
							price={product.points ?? undefined}
							priceProps={{
								fontSize: 'sm',
								fontWeight: 'semibold',
								color: useColorModeValue('black', 'white'),
							}}
						/>
					</HStack> */}
			{/* <HStack>
					<ProductRating defaultValue={product.rating} size="sm" />
					<Text fontWeight="medium" fontSize="sm" color={useColorModeValue('gray.500', 'gray.200')}>
						({product.ratingCount})
					</Text>
				</HStack> */}
			{/* </Stack>
			</Link> */}
		</Stack>
	);
};
export default ProductCard;
