import { Box, Button, Heading, Img, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const features = [
	{
		name: 'MEMBER-ONLY PROMOTIONS',
		description: 'Participate in Members-Only Promotions for chances to win exclusive prizes.',
		iconSrc:
			'https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Icon_exclusivePrizes.png',
	},
	{
		name: 'BONUS REWARDS',
		description: 'Complete your profile, share reviews & more to earn even more rewards.',
		iconSrc:
			'https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Icon_bonusRewards.png',
	},
	{
		name: 'BONUS REWARDS',
		description: 'Earn 100 points for every $1 you spend at Pinnacle!',
		iconSrc:
			'https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Icon_gearuprewards.png',
	},
];

const PerksThreeColumn: React.FC = () => {
	return (
		<Box as="section" bg="#dee5e2" color="secondary.500">
			<VStack py="10">
				<Heading textTransform="uppercase" as="h2" size="lg">
					Peak Rewards Perks
				</Heading>
				<SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} columnGap={40} px={40}>
					{features.map((feature, idx) => (
						<Stack key={`${feature.name}_${idx.toString()}`} spacing={{ base: '4', md: '5' }}>
							<Stack spacing={{ base: '1', md: '2' }} flex="1" alignItems="center">
								<Img src={feature.iconSrc} alt="reward icon" w={'54px'} h={'54px'} />
								<Text fontSize={{ base: 'xl' }} fontWeight="bold">
									{feature.name}
								</Text>
								<Text fontSize="md4">{feature.description}</Text>
							</Stack>
						</Stack>
					))}
				</SimpleGrid>
				<Button
					as={NavLink}
					to={'/join'}
					textTransform="uppercase"
					variant="solid"
					bg="#d49283"
					color="secondary.500"
					_hover={{ bg: '#d49283', opacity: '0.8' }}
					size="lg"
				>
					Join Now
				</Button>
			</VStack>
		</Box>
	);
};
export default PerksThreeColumn;
