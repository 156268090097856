import {
	Box,
	Divider,
	Flex,
	HStack,
	Icon,
	Input,
	Stack,
	StackDivider,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { MdFilterList } from 'react-icons/md';
import { blueFilters, colorFilter, sizeFilter } from './_data';
import ProductSizePicker from './ProductSizePicker';
import ProductCheckboxFilter from './ProductCheckboxFilter';
import ProductSortbySelect from './ProductSortbySelect';
import ProductColorPicker from './ProductColorPicker';
import ProductPriceRangePicker from './ProductPriceRangePicker';
import ProductFilterDrawer from './ProductFilterDrawer';

const ProductMobileFilter: React.FC = () => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	return (
		<>
			<Flex width="full" justify="space-between" display={{ base: 'flex', md: 'none' }}>
				<HStack
					as="button"
					fontSize="sm"
					type="button"
					px="3"
					py="1"
					onClick={onOpen}
					borderWidth="1px"
					rounded="md"
				>
					<Icon as={MdFilterList} />
					<Text>Filters</Text>
				</HStack>
				<ProductSortbySelect width="120px" defaultValue="23" placeholder="Sort" />
			</Flex>
			<ProductFilterDrawer isOpen={isOpen} onClose={onClose}>
				<Stack spacing="6" divider={<StackDivider />}>
					<ProductCheckboxFilter label="Brands" options={blueFilters.options} />
					<MobilePriceFilter />
					<MobileSizeFilter />
					<MobileColorFilter />
				</Stack>
			</ProductFilterDrawer>
		</>
	);
};

const MobilePriceFilter = () => {
	return (
		<Box>
			<Box fontWeight="semibold" mb="2">
				Price range
			</Box>
			<Box marginStart="2">
				<ProductPriceRangePicker defaultValue={[0, 50]} />
			</Box>
			<HStack spacing="4" mt="4">
				<HStack spacing="4">
					<Text color="gray.500" fontSize="sm">
						min
					</Text>
					<Input aria-label="Minimum price" type="number" defaultValue={10} />
				</HStack>
				<Divider width="8" opacity={1} />
				<HStack spacing="4">
					<Text color="gray.500" fontSize="sm">
						max
					</Text>
					<Input aria-label="Maximum price" type="number" defaultValue={50} />
				</HStack>
			</HStack>
		</Box>
	);
};

const MobileSizeFilter = () => {
	return (
		<Box>
			<Box fontWeight="semibold" mb="2">
				Size
			</Box>
			<ProductSizePicker hideLabel {...sizeFilter} />
		</Box>
	);
};

const MobileColorFilter = () => {
	return (
		<Box>
			<Box fontWeight="semibold" mb="2">
				Color
			</Box>
			<ProductColorPicker rootProps={{ mt: '2' }} hideLabel {...colorFilter} />
		</Box>
	);
};
export default ProductMobileFilter;
