import { Box, Heading, Img, Text, VStack } from '@chakra-ui/react';

const PageHeaderCenteredOnAccent: React.FC = () => {
	return (
		<Box as="section" bg="#e7d5d1" color="secondary.500" py={'10'}>
			<VStack gap={2}>
				<Img
					src="https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Mountain_img_blue.png"
					alt="logo"
				/>
				<Heading size={{ base: 'md', md: 'lg' }} fontWeight="bold" textTransform="uppercase">
					epic adventures deserve epic rewards
				</Heading>
				<Text fontSize={{ base: 'lg' }} maxW="3xl">
					Gear up for your next adventure with great rewards, starting now.
				</Text>
			</VStack>
		</Box>
	);
};
export default PageHeaderCenteredOnAccent;
