/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { IAppContext } from './IAppContext';

export const AppContext = React.createContext<IAppContext>({
	appConfiguration: undefined,
	setAppConfiguration: () => {},
	showCart: false,
	setShowCart: () => {},
	showAuth: false,
	setShowAuth: () => {},
	toast: {},
	setToast: () => {},
	user: undefined,
	setUser: () => {},
});
