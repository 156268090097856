import React from 'react';
import { VStack } from '@chakra-ui/react';

import WelcomeSidebar from './contentBlocks/welcomeSidebar';
import EpicRewards from './contentBlocks/epicRewards';
import SidebarPerks from './contentBlocks/perks';

const SidebarWhyJoin: React.FC = () => {
	return (
		<VStack gap={0}>
			<WelcomeSidebar />
			<EpicRewards />
			<SidebarPerks showHeader={false} />
		</VStack>
	);
};

export default SidebarWhyJoin;
