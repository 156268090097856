import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import PerksThreeColumn from '../components/PerksThreeColumn';
import FooterTwoColumnBackground from '../components/FooterTwoColumnBackground';
import HeroImageRight from '../components/hero/HeroImageRight';
import PageHeaderCentered from '../components/header/PageHeaderCentered';
import HeroImageLeft from '../components/hero/HeroImageLeft';
import EarningGallery from '../components/EarningGallery';
import { ScrollRestoration } from 'react-router-dom';

const EarnPointsPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
	<Box textAlign="center" fontSize="xl" ref={ref}>
		<ScrollRestoration />
		<CartBar />
		<NavigationBar />
		<HeroImageRight
			marginTop="40px"
			heroImageUrl="https://burst-staging.ebbo.com/media/wysiwyg/home/NF_Image.png"
			heroImageAlt="The North Face"
			contentBgColor="secondary.500"
		/>
		<HeroImageLeft
			marginTop="40px"
			heroImageUrl="https://burst-staging.ebbo.com/media/wysiwyg/home/GrandCanyon_Img.png"
			heroImageAlt="The North Face"
			contentBgColor="#20513f"
			showSpecialBadge
		/>
		<EarningGallery />
		<PageHeaderCentered />
		<PerksThreeColumn />
		<FooterTwoColumnBackground />
	</Box>
));

export const AnimatedEarnPointsPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <EarnPointsPage ref={ref} />),
);

export default EarnPointsPage;
