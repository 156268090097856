import React from 'react';
import { motion } from 'framer-motion';
import { Flex, Stack, VStack } from '@chakra-ui/react';
import { ScrollRestoration } from 'react-router-dom';
import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import FooterTwoColumnBackground from '../components/FooterTwoColumnBackground';
import SidebarWhyJoin from '../components/Sidebar_WhyJoin';
import HowItWorksTrail from '../components/contentBlocks/howItWorksTrail';
import TierBenefits from '../components/TierBenefits';

// REVIEW Move this to a config variable?
const contentWidth = '1280px';

const HowItWorksPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
	<Flex textAlign="center" fontSize="xl" ref={ref} direction={'column'} justifyContent={'center'}>
		{/* 100% page width */}
		<ScrollRestoration />
		<CartBar />
		<NavigationBar />
		<Flex
			id="backgrounddivBeige"
			direction={'column'}
			justifyContent={'flex-start'}
			alignItems={'center'}
			backgroundImage={'/media/images/HIW_background_img_beige.jpg'}
			backgroundPosition={'bottom left'}
			backgroundSize={'cover'}
			backgroundRepeat={'no-repeat'}
			width={'100%'}
		>
			{/* content width */}
			<Stack
				id="columns"
				direction={{ base: 'column', lg: 'row' }}
				spacing={{ base: '12', lg: '16' }}
				width={contentWidth}
				alignSelf={'center'}
				mb={'30px'}
			>
				<VStack id="columnLeft" gap={0} maxWidth={'420px'}>
					<SidebarWhyJoin />
				</VStack>
				<VStack id="columnRight" gap={4} width="100%">
					<HowItWorksTrail />
				</VStack>
			</Stack>
			{/* end content width */}
		</Flex>
		{/* 100% page width */}
		<TierBenefits backgroundImageSrc="/media/images/chart-bg.webp" />
		{/* 100% page width */}
		<FooterTwoColumnBackground />
	</Flex>
));

export const AnimatedHowItWorksPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <HowItWorksPage ref={ref} />),
);

export default HowItWorksPage;
