import { Box, Button, Container, Heading, Stack, Text } from '@chakra-ui/react';

const PageHeaderCentered: React.FC = () => {
	return (
		<Box as="section" bg="white" py={{ base: '16', md: '24' }}>
			<Container>
				<Stack spacing={{ base: '10' }} align="center" textAlign="center">
					<Stack spacing="10">
						<Heading
							size={{ base: 'md', md: 'lg' }}
							fontWeight="semibold"
							textTransform="uppercase"
							color="secondary.500"
						>
							redeem points for great rewards
						</Heading>
						<Text fontSize={{ base: 'sm', md: 'md' }} fontWeight="medium" color="secondary.500">
							Rewards are available while supplies last
						</Text>
					</Stack>
					<Button
						textTransform="uppercase"
						variant="solid"
						bg="secondary.500"
						color="white"
						_hover={{ opacity: '0.8' }}
						size="lg"
					>
						view rewards catalog
					</Button>
				</Stack>
			</Container>
		</Box>
	);
};
export default PageHeaderCentered;
