import { Flex, IconButton } from '@chakra-ui/react';
import { FaShoppingCart } from 'react-icons/fa';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { useContext } from 'react';
import CartDrawer from './CartDrawer';
import { AppContext } from '../../../data/globalstate';
import { NavLink } from 'react-router-dom';

const CartBar: React.FC = () => {
	const { setShowCart, showCart, appConfiguration } = useContext(AppContext);
	return (
		<Flex
			bg={'primary.500'}
			w="100%"
			minH={'48px'}
			px={'20px'}
			py={'10px'}
			justifyContent={'flex-end'}
		>
			{appConfiguration?.useCartPage && (
				<IconButton
					as={NavLink}
					aria-label="Shopping Cart"
					icon={<FaShoppingCart />}
					color={'white'}
					variant={'ghost'}
					to={'/cart'}
				/>
			)}
			{!appConfiguration?.useCartPage && (
				<IconButton
					aria-label="Shopping Cart"
					icon={<FaShoppingCart />}
					color={'white'}
					variant={'ghost'}
					onClick={() => {
						// Show the Cart Drawer
						setShowCart(!showCart);
					}}
				/>
			)}
			<ColorModeSwitcher justifySelf="flex-end" />
			<CartDrawer />
		</Flex>
	);
};
export default CartBar;
