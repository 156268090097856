import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	CircularProgress,
	Flex,
	HStack,
	SimpleGrid,
	Text,
	useColorModeValue as mode,
} from '@chakra-ui/react';
import {
	CheckboxFilterPopover,
	ColorFilterPopover,
	PriceFilterPopover,
	SizeFilterPopover,
} from './ProductFilter';
import ProductMobileFilter from './ProductMobileFilter';
import ProductGrid from './ProductGrid';
import ProductSortbySelect from './ProductSortbySelect';
import { IGetRewardsRequestParameters, IGetRewardsResponse } from '../../../domain/models';
import { useApiManager } from '../../../domain/hooks';
import { useEffect, useState } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { objectToUrlParams } from '../../../domain/utils/GeneralUtils';
import { getCookie } from '../../../domain/utils/CookieUtils';
import { Pagination } from '../pager/Pagination';

const ProductBlock: React.FC = () => {
	const [rewardsCurrentPage, setRewardsCurrentPage] = useState<number>(1);
	const [getRewardsParams, setGetRewardsParams] = useState<IGetRewardsRequestParameters>({
		PartnerCode: 'PG',
		IsActive: true,
		PageSize: 9,
		PageIndex: rewardsCurrentPage,
	});
	const { getApiUrl } = useApiManager();
	const [rewardsUrlParams, setRewardsUrlParams] = useState<string>(
		objectToUrlParams(getRewardsParams),
	);

	useEffect(() => {
		setRewardsUrlParams(objectToUrlParams(getRewardsParams));
		// eslint-disable-next-line
	}, [getRewardsParams]);

	useEffect(() => {
		setGetRewardsParams({
			...getRewardsParams,
			PageIndex: rewardsCurrentPage,
		});
		// eslint-disable-next-line
	}, [rewardsCurrentPage]);

	// useEffect(() => {
	// 	// console.log('RewardsPage::useEffect');
	// 	// Set the Initial Parameters
	// 	setGetRewardsParams({});
	// 	// void queryClient.invalidateQueries({
	// 	// 	queryKey: ['rewards', 'get', currentPage],
	// 	// 	exact: true,
	// 	// 	refetchType: 'all',
	// 	// });
	// }, [setGetRewardsParams]);

	const { data, isLoading, error } = useQuery<IGetRewardsResponse>({
		queryKey: ['rewards', 'get', rewardsUrlParams],
		// queryFn: () => getRewardsFn(),
		queryFn: () => {
			// console.log('ProductBlock::getRewards');
			// console.log(getRewardsParams);

			const token = getCookie('accessToken');
			let url = getApiUrl() + '/Reward';

			// if (getRewardsParams) {
			// const urlParams = decodeURI(new URLSearchParams(getRewardsParams).toString());
			// const urlParams = objectToUrlParams(getRewardsParams);
			// console.log(urlParams);
			url = url + '?' + rewardsUrlParams;
			// }

			// console.log(url);

			const options: RequestInit = {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + (token ? token : ''),
					'Access-Control-Allow-Origin': '*',
				},
				// body: JSON.stringify({
				// 	AccountNumber: data.accountNumber,
				// 	PromotionName: data.promotionName,
				// }),
			};

			return new Promise<IGetRewardsResponse>((resolve, reject) => {
				// const response = await fetch(url, options);
				fetch(url, options)
					.then(async (response) => {
						const data = (await response.json()) as IGetRewardsResponse;
						// console.log(response.status);
						// console.log(data);
						resolve(data);
					})
					.catch((e: unknown) => {
						reject(e);
					});
			});
		},
		placeholderData: keepPreviousData,
	});

	return (
		<Box
			maxW="7xl"
			mx="auto"
			px={{ base: '4', md: '8', lg: '12' }}
			py={{ base: '6', md: '8', lg: '12' }}
		>
			{/* TODO Wishlist Tracker */}
			{/* <ProductBreadcrumb data={breadcrumbData} />
			<Heading size="lg" mt={{ base: '6', md: '10' }} mb="8">
				Men's Watches
			</Heading> */}

			<Flex justify="space-between" align="center" display={{ base: 'none', md: 'flex' }}>
				<HStack spacing="6">
					<Text color={mode('gray.600', 'gray.400')} fontWeight="medium" fontSize="sm">
						Filter by
					</Text>
					<SimpleGrid display="inline-grid" spacing="4" columns={4}>
						<SizeFilterPopover />
						<PriceFilterPopover />
						<ColorFilterPopover />
						<CheckboxFilterPopover />
					</SimpleGrid>
				</HStack>

				<HStack flexShrink={0}>
					<Text
						as="label"
						htmlFor="sort-by"
						color={mode('gray.600', 'gray.400')}
						fontWeight="medium"
						fontSize="sm"
						whiteSpace="nowrap"
					>
						Sort by
					</Text>
					<ProductSortbySelect />
				</HStack>
			</Flex>

			<ProductMobileFilter />

			{isLoading && <CircularProgress isIndeterminate alignSelf={'center'} />}
			{error && (
				<Alert status="error">
					<AlertIcon />
					<Box>
						<AlertTitle>{error.name}</AlertTitle>
						<AlertDescription>{error.message}</AlertDescription>
					</Box>
				</Alert>
			)}

			<ProductGrid data={data} />
			<Pagination
				// siblingCount={2}
				isLoading={isLoading}
				props={{
					count: data?.pageInfo.count ?? 0,
					pageSize: data?.pageInfo.pageSize,
					page: rewardsCurrentPage,
					onPageChange: (e) => {
						// console.log('On Page Change::', e);
						setRewardsCurrentPage(e.page);
					},
				}}
			/>
		</Box>
	);
};
export default ProductBlock;
