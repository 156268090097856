import { Avatar, Button, HStack, Link, Progress, Skeleton, Text, VStack } from '@chakra-ui/react';
import { useContext } from 'react';
import { AppContext } from '../../../data/globalstate';
import { IoDiamondOutline } from 'react-icons/io5';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import { useApiManager } from '../../../domain/hooks';

const UserBar: React.FC = () => {
	const { user } = useContext(AppContext);
	const { getMemberBalance } = useApiManager();

	if (!user) {
		return null;
	}

	const { data, isLoading } = getMemberBalance;

	return (
		<HStack
			bg={'#e7d5d1'}
			// h={'116px'}
			justifyContent={'space-between'}
			px={'20px'}
			spacing={'20px'}
			alignSelf={'stretch'}
		>
			<HStack>
				<Avatar />
				<VStack justifyContent={'flex-start'} alignItems={'flex-start'} gap={0}>
					<Text color={'black'}>
						Welcome Back, <span style={{ color: '#000' }}>{user.burstProfile.firstName}</span>
					</Text>
					<Link textTransform={'uppercase'} textDecoration={'underline'} color={'black'}>
						view account
					</Link>
				</VStack>
				<Skeleton isLoaded={!isLoading}>
					<Button
						leftIcon={<IoDiamondOutline />}
						color={'white'}
						backgroundColor={'secondary.500'}
						ml={10}
					>
						Points Balance:&nbsp;
						<span style={{ color: '#e7d5d1' }}>
							{data?.balance.balance.toLocaleString('en-US') ?? 0}
						</span>
					</Button>
				</Skeleton>
			</HStack>
			<VStack gap={0} textColor={'secondary.500'} my={5}>
				<Text textTransform={'uppercase'} fontSize={'md'}>
					Status: Top Tier
				</Text>
				<HStack>
					<Text textTransform={'uppercase'} fontSize={'md'}>
						points to next tier:
					</Text>
					<Text textTransform={'uppercase'} fontWeight={'bold'} fontSize={'md'}>
						500
					</Text>
				</HStack>
				{/* TODO Extract Tier Progress to Own Component */}
				<HStack minW={360} position={'relative'} justifyContent={'space-between'}>
					<Progress
						value={58}
						size={'md'}
						colorScheme="secondary"
						w={'90%'}
						borderRadius={'50vh'}
						position={'absolute'}
						left={'5%'}
						zIndex={0}
					/>
					<VStack zIndex={1}>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							base
						</Text>
						{/* <FaRegCircle /> */}
						<FaCircle
							fill="white"
							// stroke="red"
							// strokeWidth={'2px'}
							// style={{ border: '2px solid red' }}
							// style={{ outline: '2px solid red', outlineOffset: 2 }}
						/>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							1,000
						</Text>
					</VStack>
					<VStack zIndex={1}>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							ridge
						</Text>
						{/* <FaRegCircle /> */}
						<FaCircle />
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							2,500
						</Text>
					</VStack>
					<VStack zIndex={1}>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							crest
						</Text>
						<FaRegCircle />
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							5,000
						</Text>
					</VStack>
					<VStack zIndex={1}>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							summit
						</Text>
						<FaRegCircle />
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							10,000
						</Text>
					</VStack>
				</HStack>

				{/* <HStack minW={360}>
					<VStack>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							base
						</Text>
						<FaRegCircle />
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							1,000
						</Text>
					</VStack>
					<Progress value={80} size={'md'} colorScheme="secondary" w={'33%'} />
					<VStack>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							ridge
						</Text>
						<FaRegCircle />
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							2,500
						</Text>
					</VStack>
					<Progress value={0} size={'md'} colorScheme="secondary" w={'33%'} />
					<VStack>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							crest
						</Text>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							5,000
						</Text>
					</VStack>
					<Progress value={0} size={'md'} colorScheme="secondary" w={'33%'} />
					<VStack>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							summit
						</Text>
						<Text textTransform={'uppercase'} fontSize={'sm'}>
							10,000
						</Text>
					</VStack>
				</HStack> */}
			</VStack>
		</HStack>
	);
};
export default UserBar;
