import {
	Button,
	Flex,
	Image,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Link,
	Stack,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import CartPriceTag from './CartPriceTag';
import { IRewardType } from '../../../domain/models';
import { Link as NavLink } from 'react-router-dom';

interface CartItemProps {
	// name: string;
	// description: string;
	quantity: number;
	// price: number;
	// currency: string;
	// imageUrl: string;
	itemData: IRewardType;
	onChangeQuantity?: (quantity: number) => void;
	onClickDelete?: () => void;
}

const CartItem: React.FC<CartItemProps> = (props) => {
	const {
		// name,
		// description,
		quantity,
		// imageUrl,
		// currency,
		// price,
		itemData,
		onChangeQuantity,
		onClickDelete,
	} = props;

	const defaultImage = itemData.imageAlternatives?.find((v) => v.isPrimary);

	return (
		<Stack direction="row" spacing="5">
			<Image
				rounded="md"
				minWidth="24"
				maxWidth="24"
				height={{ base: '20', md: '24' }}
				fit="cover"
				src={defaultImage?.url ?? undefined}
				alt={defaultImage?.altText ?? undefined}
				draggable="false"
				loading="lazy"
			/>
			<Stack width="full" spacing="3">
				<Stack direction={{ base: 'column', md: 'row' }} spacing="3" alignItems="flex-start">
					<NavLink to={itemData.rewardId ? `/product/${itemData.rewardId}` : '#'}>
						<Stack spacing="0.5" width="full">
							<Text fontWeight="medium">{itemData.voucherName}</Text>
							{/* NOTE: Description Not supported in current burst data */}
							{/* <Text color={useColorModeValue('gray.500', 'gray.300')}>{description}</Text> */}
						</Stack>
					</NavLink>
					<CartPriceTag price={itemData.points ?? 0} currency={'POINTS'} showEach />
					<CartPriceTag price={(itemData.points ?? 0) * quantity} currency={'POINTS'} />
				</Stack>
				<Flex width="full" justifyContent="space-between" alignItems="center">
					<InputGroup>
						<InputLeftAddon
							as={Button}
							onClick={() => {
								// console.log('decrease');
								onChangeQuantity?.(quantity - 1);
							}}
						>
							-
						</InputLeftAddon>
						<Input
							type="number"
							aria-label="Quantity"
							readOnly
							width="16"
							value={quantity}
							onChange={(e) => {
								onChangeQuantity?.(+e.currentTarget.value);
							}}
							textAlign={'center'}
						/>
						<InputRightAddon
							as={Button}
							onClick={() => {
								// console.log('increase');
								onChangeQuantity?.(quantity + 1);
							}}
						>
							+
						</InputRightAddon>
					</InputGroup>
					<Link
						as="button"
						type="button"
						fontWeight="medium"
						fontSize="sm"
						color={useColorModeValue('blue.500', 'blue.200')}
						onClick={onClickDelete}
					>
						Remove
					</Link>
				</Flex>
			</Stack>
		</Stack>
	);
};
export default CartItem;
