import { Button, ButtonProps } from '@chakra-ui/react';
import { useContext } from 'react';
import { AppContext } from '../../../data/globalstate';
import { NavLink } from 'react-router-dom';

// interface ISignInButtonProps extends ButtonProps {}

const SignInButton: React.FC<ButtonProps> = (props) => {
	const { showAuth, setShowAuth, appConfiguration } = useContext(AppContext);
	return (
		<>
			{!appConfiguration?.useLoginPage && (
				<Button
					{...props}
					onClick={() => {
						// Show Auth Popup
						setShowAuth(!showAuth);
					}}
				>
					Sign In
				</Button>
			)}
			{appConfiguration?.useLoginPage && (
				<Button {...props} as={NavLink} to={'/login'}>
					Sign In
				</Button>
			)}
		</>
	);
};
export default SignInButton;
