import * as yup from 'yup';

export const AuthValidationScheme = yup.object({
	email: yup.string().email('Must be a valid Email Address!').required('Email is Required!'),
	password: yup.string().required('Password is Required'),
});

export interface IAuthForm {
	email?: string;
	password?: string;
}
