import { Flex, Heading, Text, VStack, Box } from '@chakra-ui/react';

interface ISpecialBadge {
	title: string;
	keyText: string;
	subText: string;
}
const SpecialBadge: React.FC<ISpecialBadge> = ({ title, keyText, subText }) => {
	return (
		<Box position={'absolute'} bottom={'10px'} left={'50%'} transform={'translate(-50%,0)'}>
			<Flex
				bg={'secondary.500'}
				width={'fit-content'}
				border={'2px solid white'}
				borderRadius={'50%'}
				aspectRatio={1}
			>
				<VStack gap={2} justifyContent={'center'}>
					<Text fontWeight={'bold'} size={'md'} maxW={'75%'} lineHeight={'90%'}>
						{title}
					</Text>
					<Heading as={'p'} size={'3xl'}>
						{keyText}
					</Heading>
					<Text size={'md'} fontWeight={'bold'} maxW={'75%'} lineHeight={'90%'}>
						{subText}
					</Text>
				</VStack>
			</Flex>
		</Box>
	);
};
export default SpecialBadge;
