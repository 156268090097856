import { useToast } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../data/globalstate';

const ToastNotification: React.FC = () => {
	const { toast } = useContext(AppContext);
	const chakraToast = useToast();

	useEffect(() => {
		// Only show if we have a title atleast!
		if (toast.title) {
			// toast.onCloseComplete = () => {
			// 	console.log('Toast Closed!');
			// 	setToast({});
			// };
			chakraToast(toast);
		}
	}, [toast, chakraToast]);

	return undefined;
};
export default ToastNotification;
