import React from 'react';
import { Box, Flex, Heading, Img, Text } from '@chakra-ui/react';
import PrimaryLink from '../buttons/primaryLink';

const HowItWorksTrail: React.FC = () => {
	return (
		<Box
			marginTop={'50px'}
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			width="100%"
			color={'black'}
		>
			<Flex
				id="step1"
				flexDirection={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				width="80%"
				marginLeft="143px"
			>
				<Img src="/media/images/step1_map_icon.svg" alt="Step 1" width={'71px'} height={'102px'} />
				<Box>
					<Box width={'180px'} marginX="auto" marginBottom="15px">
						<Heading
							as="h2"
							fontSize=""
							fontWeight={'bold'}
							textTransform="uppercase"
							marginTop={'15px'}
							marginBottom={'10px'}
						>
							SIGN UP
						</Heading>
						<Text fontSize={'16px'}>Register for free and start earning points.</Text>
					</Box>
					<PrimaryLink to="/join" linkCopy="Create An Account" />
				</Box>
			</Flex>
			<Img
				src="/media/images/VdottedLine_1to2.svg"
				alt="Trail from Step 1 to 2"
				width={'443px'}
				height={'234px'}
				marginTop={'-20px'}
				marginLeft={'220px'}
			/>
			<Flex
				id="step2"
				flexDirection={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				width="42%"
				marginLeft="60px"
			>
				<Img
					src="/media/images/step2_map_icon.svg"
					alt="Step 2"
					width={'71px'}
					height={'102px'}
					marginX={'10px'}
				/>
				<Box>
					<Box width={'310px'} marginBottom="15px">
						<Heading
							as="h2"
							fontSize=""
							fontWeight={'bold'}
							textTransform="uppercase"
							marginTop={'15px'}
							marginBottom={'10px'}
						>
							EARN POINTS
						</Heading>
						<Text fontSize={'16px'}>
							Earn points for gearing up, then get even more for completing activities.
						</Text>
					</Box>
					<PrimaryLink to="/earn-points" linkCopy="Start Earning" />
				</Box>
			</Flex>
			<Img
				src="/media/images/VdottedLine_2to3.svg"
				alt="Trail from Step 2 to 3"
				width={'443px'}
				height={'234px'}
				marginTop={'-20px'}
				marginLeft={'46px'}
			/>
			<Flex
				id="step3"
				flexDirection={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				width="50%"
				marginLeft="284px"
			>
				<Img
					src="/media/images/step3_map_icon.svg"
					alt="Step 3"
					width={'71px'}
					height={'102px'}
					marginX={'10px'}
				/>
				<Box>
					<Box width={'300px'} marginBottom="15px">
						<Heading
							as="h2"
							fontSize=""
							fontWeight={'bold'}
							textTransform="uppercase"
							marginTop={'15px'}
							marginBottom={'10px'}
						>
							GET REWARDED
						</Heading>
						<Text fontSize={'16px'}>
							Redeem your points for rewards like gift cards, accessories, swag and chances to win
							exciting prizes.
						</Text>
					</Box>
					<PrimaryLink to="/rewards" linkCopy="Shop Rewards Catalog" />
				</Box>
			</Flex>
		</Box>
	);
};

export default HowItWorksTrail;
