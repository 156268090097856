import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ICartData, ICartItem, ICartManager } from '../models';

export const useCartManager = (): ICartManager => {
	const queryClient = useQueryClient();

	const getCartItems = useQuery({
		queryKey: ['cart', 'items'],
		queryFn: () => {
			// console.log('useCartManager::getCartItems');
			let lsData = localStorage.getItem('cart');
			if (lsData === null) {
				// Populate empty
				const newD: ICartData = { items: [], subtotal: 0 };
				localStorage.setItem('cart', JSON.stringify(newD));
				lsData = localStorage.getItem('cart');
				if (lsData) {
					const d = JSON.parse(lsData) as ICartData;
					return Promise.resolve(d.items);
				}
				return Promise.reject(new Error('Invalid Cart Data'));
			} else {
				const d = JSON.parse(lsData) as ICartData;
				return Promise.resolve(d.items);
			}
			// return Promise.reject(new Error('Invalid Cart Data'));
		},
	});

	const setCartItems = useMutation({
		mutationFn: (items: ICartItem[]) => {
			return new Promise<void>((resolve) => {
				const lsData = localStorage.getItem('cart');
				if (lsData === null) {
					let subtotal = 0;
					items.forEach((v) => {
						subtotal += v.quantity * (v.itemData.points ?? 0);
					});
					const newD: ICartData = { items: items, subtotal: subtotal };
					localStorage.setItem('cart', JSON.stringify(newD));
					resolve();
					return;
				}
				const d = JSON.parse(lsData) as ICartData;
				d.items = items;
				let subT = 0;
				items.forEach((v) => {
					subT += v.quantity * (v.itemData.points ?? 0);
				});
				d.subtotal = subT;
				localStorage.setItem('cart', JSON.stringify(d));
				resolve();
			});
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ['cart'] });
			await queryClient.fetchQuery({ queryKey: ['cart'] });
		},
		scope: {
			id: 'cart',
		},
	});

	const changeQuantity = useMutation({
		mutationFn: (data: { item: ICartItem; quantity: number }) => {
			return new Promise<void>((resolve, reject) => {
				//Do NOT allow less than 1
				if (data.quantity < 1) {
					resolve();
					return;
				}
				const items = getCartItems.data;
				// const items = undefined;
				if (!items) {
					resolve();
					return;
				}

				const match = items.find((v) => v.itemData.rewardId === data.item.itemData.rewardId);
				if (match) {
					match.quantity = data.quantity;
				}
				setCartItems.mutateAsync(items).catch((e: unknown) => {
					reject(e);
					return;
				});

				resolve();
			});
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ['cart'] });
			await queryClient.fetchQuery({ queryKey: ['cart'] });
		},
		scope: {
			id: 'cart',
		},
	});

	return {
		getCart: useQuery({
			queryKey: ['cart'],
			queryFn: () => {
				// console.log('useCartManager::getCart');
				let lsData = localStorage.getItem('cart');
				// console.log(lsData);
				if (lsData === null) {
					//Populate empty
					const newD: ICartData = { items: [], subtotal: 0 };
					localStorage.setItem('cart', JSON.stringify(newD));
					lsData = localStorage.getItem('cart');
					if (lsData) {
						const d = JSON.parse(lsData) as ICartData;
						// cartManager.setCartData?.(d);
						return Promise.resolve(d);
					}
					return Promise.reject(new Error('Invalid Cart Data'));
				} else {
					const d = JSON.parse(lsData) as ICartData;
					// cartManager.setCartData?.(d);
					// console.log(d);
					return Promise.resolve(d);
				}
				// return Promise.reject(new Error('Invalid Cart Data'));
			},
		}),
		getCartItems: getCartItems,
		setCartItems: setCartItems,
		removeCartItem: useMutation({
			mutationFn: (item: ICartItem) => {
				return new Promise<void>((resolve) => {
					// console.log(item);
					const lsData = localStorage.getItem('cart');
					if (lsData !== null) {
						const d = JSON.parse(lsData) as ICartData;
						const newItems = d.items.filter((v) => v.itemData.rewardId !== item.itemData.rewardId);
						let subT = 0;
						newItems.forEach((v) => {
							subT += v.quantity * (v.itemData.points ?? 0);
						});
						d.items = newItems;
						d.subtotal = subT;
						localStorage.setItem('cart', JSON.stringify(d));
					}
					resolve();
				});
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['cart'] });
				await queryClient.fetchQuery({ queryKey: ['cart'] });
			},
			scope: {
				id: 'cart',
			},
		}),
		addCartItem: useMutation({
			mutationFn: (item: ICartItem) => {
				return new Promise<void>((resolve) => {
					// console.log(item);
					const lsData = localStorage.getItem('cart');
					if (lsData !== null) {
						const d = JSON.parse(lsData) as ICartData;
						const itmInCart = d.items.find((v) => v.itemData.rewardId === item.itemData.rewardId);
						if (itmInCart) {
							itmInCart.quantity += 1;
						} else {
							d.items.push(item);
						}
						let subT = 0;
						d.items.forEach((v) => {
							subT += v.quantity * (v.itemData.points ?? 0);
						});
						d.subtotal = subT;
						localStorage.setItem('cart', JSON.stringify(d));
					}
					resolve();
				});
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['cart'] });
				await queryClient.fetchQuery({ queryKey: ['cart'] });
			},
			scope: {
				id: 'cart',
			},
		}),
		changeQuantity: changeQuantity,
	};
};
