import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import FooterTwoColumnBackground from '../components/FooterTwoColumnBackground';
import { ScrollRestoration } from 'react-router-dom';
import ProductBlock from '../components/products/ProductBlock';

const RewardsPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<ProductBlock />
			<FooterTwoColumnBackground />
		</Box>
	);
});

export const AnimatedRewardsPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <RewardsPage ref={ref} />),
);

export default RewardsPage;
