import React from 'react';
import { Box, Flex, Heading, Img, Stack, Text } from '@chakra-ui/react';

export interface ISidebarPerks {
	showHeader: boolean;
}

const perks = [
	{
		name: 'EXCLUSIVE PRIZES',
		description: 'Participate in Members-Only Promotions for chances to win exclusive prizes.',
		iconSrc:
			'https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Icon_exclusivePrizes.png',
	},
	{
		name: 'BONUS REWARDS',
		description: 'Complete your profile, share reviews & more to earn even more rewards.',
		iconSrc:
			'https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Icon_bonusRewards.png',
	},
	{
		name: 'GEAR UP REWARDS',
		description: 'Earn 100 points for every $1 you spend at Pinnacle!',
		iconSrc:
			'https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Icon_gearuprewards.png',
	},
];

const SidebarPerks: React.FC<ISidebarPerks> = ({ showHeader }) => {
	return (
		<Box
			as="section"
			backgroundColor="white"
			color="secondary.500"
			width="100%"
			paddingBottom="60px"
		>
			<Stack paddingY="10">
				{showHeader && (
					<Heading as="h2" size="lg" textTransform="uppercase">
						Peak Rewards Perks
					</Heading>
				)}
				<Flex gap={4} flexDirection="column" justifyContent="flex-start" alignItems="center">
					{perks.map((perk, idx) => (
						<Flex
							key={`${perk.name}_${idx.toString()}`}
							flexDirection="column"
							alignItems="center"
							maxWidth="280px"
							marginTop={idx === 0 ? '40px' : '50px'}
							height="175px"
							justifyContent="space-between"
						>
							<Img src={perk.iconSrc} alt={`${perk.name} Reward Icon`} w={'54px'} h={'54px'} />
							<Text fontSize="20px" fontWeight="bold" textTransform="uppercase">
								{perk.name}
							</Text>
							<Text fontSize="16px" lineHeight={1.75}>
								{perk.description}
							</Text>
						</Flex>
					))}
				</Flex>
			</Stack>
		</Box>
	);
};

export default SidebarPerks;
