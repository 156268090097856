import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { AppContext } from '../../data/globalstate';
import {
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails,
	ISignUpResult,
	CognitoUserAttribute,
	CognitoUserSession,
	CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import { setCookie } from '../utils/CookieUtils';
import { IAuthLoginRequestParameters, IAuthLoginResponse, IAuthManager } from '../models';
import { useApiManager } from './useApiManager';

export const useAuthManager = (): IAuthManager => {
	const { appConfiguration, setUser } = useContext(AppContext);
	const { login: apiLogin } = useApiManager();
	// FIXME Verify Refresh Session works correctly
	const refreshSessionHandler = useMutation({
		mutationFn: (data: { token: CognitoRefreshToken; user: CognitoUser }) => {
			return new Promise<CognitoUserSession>((resolve, reject) => {
				data.user.refreshSession(
					data.token,
					(err: Error | null, session: CognitoUserSession | null) => {
						if (err) {
							reject(err);
							return;
						}
						if (session === null) {
							reject('Invalid Session!');
							return;
						}
						resolve(session);
					},
				);
			});
		},
		scope: {
			id: 'auth:refreshSession',
		},
	});
	const updateUser = (
		session: CognitoUserSession,
		user: CognitoUser,
		resolve: (value: boolean | PromiseLike<boolean>) => void,
		reject: (reason?: unknown) => void,
	) => {
		const at = session.getAccessToken();
		setCookie('accessToken', at.getJwtToken(), at.getExpiration());

		user.getUserAttributes((e?: Error, a?: CognitoUserAttribute[]) => {
			// console.log('useAuthManager::login::cognitoUser.getUserAttributes', e);
			// console.log('useAuthManager::login::cognitoUser.getUserAttributes', a);
			if (e) {
				reject(e);
			}
			if (a) {
				const burstIdAttribute = a.find((v) => {
					return v.Name === 'custom:burst_identifier';
				});

				if (burstIdAttribute) {
					apiLogin
						.mutateAsync({ accountNumber: burstIdAttribute.Value })
						.then((v) => {
							// console.log(v);
							setUser({
								cognitoUser: user,
								burstId: burstIdAttribute.Value,
								burstProfile: v,
							});
							resolve(session.isValid());
						})
						.catch((err: unknown) => {
							reject(err);
						});
				} else {
					reject('Invalid Loyalty User!');
				}
			} else {
				reject('Invalid User Attributes!');
			}
		});
	};

	return {
		login: useMutation({
			mutationFn: (data: IAuthLoginRequestParameters) => {
				if (appConfiguration === undefined) {
					return Promise.reject('Invalid App Configuration!');
				}
				const userPool = new CognitoUserPool({
					UserPoolId: appConfiguration.cognitoUserPoolId,
					ClientId: appConfiguration.cognitoClientId,
				});
				const authenticationDetails = new AuthenticationDetails({
					Username: data.userName,
					Password: data.password,
				});

				const cognitoUser = new CognitoUser({ Username: data.userName, Pool: userPool });

				return new Promise<IAuthLoginResponse>((resolve, reject) => {
					cognitoUser.authenticateUser(authenticationDetails, {
						onSuccess: (res) => {
							// console.log(res);
							const at = res.getAccessToken();
							// console.log(new Date(at.getExpiration()));
							cognitoUser.getSession((e: null | Error, s: null | CognitoUserSession) => {
								// console.log(e);
								// console.log(s);
								// console.log(cognitoUser);
								if (s !== null) {
									setCookie('accessToken', at.getJwtToken(), at.getExpiration());
								}
							});
							cognitoUser.getUserAttributes((e?: Error, a?: CognitoUserAttribute[]) => {
								// console.log('useAuthManager::login::cognitoUser.getUserAttributes', e);
								// console.log('useAuthManager::login::cognitoUser.getUserAttributes', a);
								if (e) {
									reject(e);
								}
								if (a) {
									const burstIdAttribute = a.find((v) => {
										return v.Name === 'custom:burst_identifier';
									});

									if (burstIdAttribute) {
										resolve({
											cognitoUser: cognitoUser,
											burstId: burstIdAttribute.Value,
										});
									} else {
										reject('Invalid Loyalty User!');
									}
								} else {
									reject('Invalid User Attributes!');
								}
							});
						},
						onFailure: (err: Error) => {
							reject(err);
						},
					});
				});
			},
			scope: {
				id: 'auth:login',
			},
		}),
		register: useMutation({
			mutationFn: (data: {
				userName: string;
				password: string;
				firstName: string;
				lastName: string;
				burstId?: string;
			}) => {
				if (appConfiguration === undefined) {
					return Promise.reject('Invalid App Configuration!');
				}
				const userPool = new CognitoUserPool({
					UserPoolId: appConfiguration.cognitoUserPoolId,
					ClientId: appConfiguration.cognitoClientId,
				});

				const attributes = [
					new CognitoUserAttribute({
						Name: 'email',
						Value: data.userName,
					}),
					new CognitoUserAttribute({
						Name: 'family_name',
						Value: data.lastName,
					}),
					new CognitoUserAttribute({
						Name: 'given_name',
						Value: data.firstName,
					}),
					new CognitoUserAttribute({
						Name: 'name',
						Value: `${data.firstName} ${data.lastName}`,
					}),
				];

				if (data.burstId) {
					attributes.push(
						new CognitoUserAttribute({
							Name: 'custom:burst_identifier',
							Value: data.burstId,
						}),
					);
				}

				return new Promise<CognitoUser>((resolve, reject) => {
					userPool.signUp(
						data.userName,
						data.password,
						attributes,
						[],
						(err: Error | undefined, result: ISignUpResult | undefined) => {
							if (err) {
								reject(err);
								return;
							}
							if (!result) {
								reject('Invalid Registration Result');
								return;
							}
							const cognitoUser = result.user;
							console.log('user name is ' + cognitoUser.getUsername());
							resolve(cognitoUser);
						},
					);
				});
			},
			scope: {
				id: 'auth:register',
			},
		}),
		confirmRegistration: useMutation({
			mutationFn: (data: { cognitoUser: CognitoUser; confirmationCode: string }) => {
				return new Promise<void>((resolve, reject) => {
					data.cognitoUser.confirmRegistration(data.confirmationCode, true, (err, result) => {
						if (err) {
							reject(err);
							return;
						}
						if (!result) {
							reject('Invalid Registration Confirmation Result');
							return;
						}
						console.log('confirmaiton call result', result);
						resolve();
					});
				});
			},
			scope: {
				id: 'auth:confirmRegistration',
			},
		}),
		updateUserAttribute: useMutation({
			mutationFn: (data: { cognitoUser: CognitoUser; attributes: CognitoUserAttribute[] }) => {
				return new Promise<void>((resolve, reject) => {
					console.log('Called updateUserAttribute');
					data.cognitoUser.updateAttributes(data.attributes, (err, result, details) => {
						if (err) {
							reject(err);
							return;
						}
						if (!result) {
							reject('Invalid Registration Confirmation Result');
							return;
						}
						console.log(err);
						console.log(result);
						console.log(details);
						resolve();
					});
				});
			},
			scope: {
				id: 'auth:updateUserAttribute',
			},
		}),
		refreshSession: refreshSessionHandler,
		validate: useMutation({
			mutationFn: () => {
				// console.log('useAuthManager::validate');
				if (appConfiguration === undefined) {
					return Promise.reject('Invalid App Configuration!');
				}
				const userPool = new CognitoUserPool({
					UserPoolId: appConfiguration.cognitoUserPoolId,
					ClientId: appConfiguration.cognitoClientId,
				});
				const cognitoUser = userPool.getCurrentUser();

				return new Promise<boolean>((resolve, reject) => {
					// console.log('useAuthManager::validate::cognitoUser', cognitoUser);
					if (cognitoUser !== null) {
						cognitoUser.getSession(function (
							err: Error | null,
							session: CognitoUserSession | null,
						) {
							if (err) {
								alert(err.message || JSON.stringify(err));
								reject(err);
								return;
							}
							if (session === null) {
								reject('Invalid User Session!');
								return;
							}
							// console.log('session validity: ', session.isValid());
							// console.log('session: ', session);

							if (session.isValid()) {
								updateUser(session, cognitoUser, resolve, reject);
							} else {
								const rt = session.getRefreshToken();
								refreshSessionHandler
									.mutateAsync({ token: rt, user: cognitoUser })
									.then((sess) => {
										if (sess.isValid()) {
											updateUser(sess, cognitoUser, resolve, reject);
										} else {
											resolve(false);
										}
									})
									.catch((err: unknown) => {
										reject(err);
									});
							}
						});
					} else {
						resolve(false);
					}
				});
			},
			scope: {
				id: 'auth:validate',
			},
		}),
		// FIXME Verify forgotPassword works!
		forgotPassword: useMutation({
			mutationFn: (data: { userName: string }) => {
				if (appConfiguration === undefined) {
					return Promise.reject('Invalid App Configuration!');
				}
				const userPool = new CognitoUserPool({
					UserPoolId: appConfiguration.cognitoUserPoolId,
					ClientId: appConfiguration.cognitoClientId,
				});
				const cognitoUser = new CognitoUser({ Username: data.userName, Pool: userPool });
				return new Promise<void>((resolve, reject) => {
					cognitoUser.forgotPassword({
						onSuccess: function (data) {
							// successfully initiated reset password request
							console.log('CodeDeliveryData from forgotPassword: ', data);
							resolve();
						},
						onFailure: function (err) {
							alert(err.message || JSON.stringify(err));
							reject(err);
						},
						//Optional automatic callback
						// inputVerificationCode: function (data) {
						// 	console.log('Code sent to: ', data);
						// 	var code = document.getElementById('code').value;
						// 	var newPassword = document.getElementById('new_password').value;
						// 	cognitoUser.confirmPassword(verificationCode, newPassword, {
						// 		onSuccess() {
						// 			console.log('Password confirmed!');
						// 		},
						// 		onFailure(err) {
						// 			console.log('Password not confirmed!');
						// 		},
						// 	});
						// },
					});
				});
			},
			scope: {
				id: 'auth:forgotPassword',
			},
		}),
	};
};
