import {
	Box,
	Button,
	Heading,
	HStack,
	Icon,
	Link,
	Stack,
	StackProps,
	Text,
	useColorModeValue,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { FiHeart } from 'react-icons/fi';
import { Product } from './_data';
import ProductPriceTag from './ProductPriceTag';
import ProductImageGallery from './ProductImageGallery';
import { isNumber } from 'lodash';
import { useCartManager } from '../../../domain/hooks';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../data/globalstate';
import { GiSevenPointedStar } from 'react-icons/gi';
import { TbJewishStarFilled } from 'react-icons/tb';

interface ProductQuickShopProps {
	product: Product;
	rootProps?: StackProps;
}

export const ProductQuickShop: React.FC<ProductQuickShopProps> = ({ product, rootProps }) => {
	const { user } = useContext(AppContext);
	const navigate = useNavigate();
	const { addCartItem } = useCartManager();
	const toast = useToast();
	const textAccentColor = useColorModeValue('gray.600', 'gray.400');

	return (
		<Stack
			direction={{ base: 'column', md: 'row' }}
			spacing={{ base: '8', lg: '16' }}
			{...rootProps}
		>
			<Box flex="1">
				<Stack spacing={{ base: '4', md: '8' }}>
					<Stack spacing={{ base: '2', md: '4' }}>
						{/* <Stack spacing="3">
							<ProductBadge bg="red.500" color="white">
								On Sale
							</ProductBadge>
						</Stack> */}
						<Heading size="lg" fontWeight="medium">
							{product.voucherName}
						</Heading>
						{/* <Stack
							direction={{ base: 'column', md: 'row' }}
							spacing="1"
							align="baseline"
							justify="space-between"
						> */}
						{/* <HStack spacing="2" alignSelf="baseline">
								<ProductRating defaultValue={product.rating} size="sm" />
								<Link
									href="#"
									fontSize="sm"
									fontWeight="medium"
									color={textAccentColor}
								>
									{product.ratingCount} Reviews
								</Link>
							</HStack> */}
						{/* </Stack> */}
						{product.voucherTypeDescription && (
							<Text color={textAccentColor}>{product.voucherTypeDescription}</Text>
						)}
					</Stack>
					{/* <Stack spacing={{ base: '4', md: '8' }} direction={{ base: 'column', md: 'row' }}>
						<Stack flex="1">
							<ProductColorPicker
								defaultValue="Black"
								options={[
									{ label: 'Black', value: '#000' },
									{ label: 'Dark Gray', value: '#666' },
									{ label: 'Light Gray', value: '#BBB' },
								]}
							/>
							<HStack spacing="1" color={textAccentColor}>
								<Icon as={FiClock} />
								<Text fontSize="xs" fontWeight="medium">
									Low stock
								</Text>
							</HStack>
						</Stack>
						<Stack flex="1">
							<ProductSizePicker
								defaultValue="32"
								options={[
									{ label: '32mm', value: '32' },
									{ label: '36mm', value: '36' },
									{ label: '40mm', value: '40' },
								]}
							/>
							<HStack spacing="1" color={textAccentColor}>
								<Icon as={RiRulerLine} />
								<Link href="#" fontSize="xs" fontWeight="medium" textDecoration="underline">
									View our sizing guide
								</Link>
							</HStack>
						</Stack>
					</Stack> */}
					<ProductPriceTag
						price={product.points ?? undefined}
						// salePrice={product.salePrice}
						currency={'POINTS'}
						rootProps={{ fontSize: '4xl' }}
					/>
					{/* <HStack spacing={{ base: '4', md: '8' }} align="flex-end" justify="space-evenly">
						<Box flex="1"><ProductQuantityPicker defaultValue={1} max={5} /></Box>
						<Box flex="1">
							<Button
								variant="outline"
								size="lg"
								fontSize="md"
								width="full"
								leftIcon={<Icon as={FiHeart} boxSize="4" />}
							>
								Favorite
							</Button>
						</Box>
					</HStack> */}
					<Stack spacing="3">
						<Button
							variant="outline"
							size="lg"
							fontSize="md"
							width="full"
							leftIcon={<Icon as={FiHeart} boxSize="4" />}
						>
							Favorite
						</Button>
						<Button
							colorScheme="primary"
							backgroundColor={'primary.500'}
							color={'white'}
							size="lg"
							disabled={!isNumber(product.points)}
							onClick={() => {
								if (user) {
									// TODO Validate Available Points
									// console.log('Add To Cart Clicked!');
									addCartItem.mutate({ itemData: product, quantity: 1 });
									toast({
										// title: 'Account created.',
										description: `Added ${product.voucherName ?? ''} to your cart!`,
										status: 'success',
										duration: 5000,
										isClosable: true,
									});
								} else {
									navigate('/login');
								}
							}}
							isDisabled={!isNumber(product.points)}
						>
							Add to Cart
						</Button>
						<Link
							as={NavLink}
							textAlign="center"
							to={product.rewardId ? `/product/${product.rewardId}` : '#'}
						>
							View full details
						</Link>
					</Stack>
				</Stack>
			</Box>
			<Box flex="1">
				{product.voucherTypeSubCategory === 'SWEEPSTAKE' && (
					<VStack position={'relative'}>
						<HStack
							backgroundColor={'primary.500'}
							color={'white'}
							py={2}
							justifyContent={'center'}
							fontWeight={'semibold'}
							alignSelf={'stretch'}
						>
							<Text textTransform={'uppercase'}>{`sweepstakes entry`}</Text>
						</HStack>
						<HStack mb={2}>
							<Icon
								as={TbJewishStarFilled}
								boxSize={8}
								fill={'primary.500'}
								transform={'rotate(30deg)'}
							/>
							<Icon
								as={TbJewishStarFilled}
								boxSize={8}
								fill={'#e7d5d1'}
								transform={'rotate(30deg)'}
							/>
							<Icon
								as={TbJewishStarFilled}
								boxSize={8}
								fill={'primary.500'}
								transform={'rotate(30deg)'}
							/>
						</HStack>
						<Box
							position={'absolute'}
							bottom={'-80%'}
							left={'0%'}
							zIndex={40}
							textAlign={'center'}
							maxW={'80px'}
							transform={'rotate(-30deg)'}
						>
							<Icon
								as={GiSevenPointedStar}
								boxSize={36}
								fill={'secondary.500'}
								transform={'rotate(30deg)'}
								pos={'absolute'}
								top={'-2.5rem'}
								left={'-2rem'}
								zIndex={0}
							/>
							<Icon
								as={TbJewishStarFilled}
								boxSize={5}
								fill={'#e7d5d1'}
								transform={'rotate(30deg)'}
								position={'relative'}
								zIndex={1}
							/>
							{/* TODO We'll need to get this dynamic content from somewhere. */}
							<Text
								position={'relative'}
								zIndex={1}
								fontSize={'small'}
								textTransform={'uppercase'}
								fontWeight={'semibold'}
								lineHeight={1}
							>
								Grand Canyon
							</Text>
						</Box>
					</VStack>
				)}
				{product.voucherTypeSubCategory !== 'SWEEPSTAKE' && (
					<HStack
						backgroundColor={'primary.500'}
						color={'white'}
						py={2}
						justifyContent={'center'}
						fontWeight={'semibold'}
					>
						{product.quantity && (
							<Text textTransform={'uppercase'}>{`${product.quantity.toString()} in stock`}</Text>
						)}
						{!product.quantity && <Text textTransform={'uppercase'}>{`sold out`}</Text>}
					</HStack>
				)}
				<ProductImageGallery images={product.imageAlternatives ?? []} />
			</Box>
		</Stack>
	);
};
